import login from './en-US/login';
import signup from './en-US/signup';
import shipment from './en-US/shipment';
import contact from './en-US/contact';
import shipment_changes from './en-US/shipment_changes';
import contact_changes from './en-US/contact_changes';
import transaction_changes from './en-US/transaction_changes';
import accounting from './en-US/accounting';
import isf from './en-US/isf';
import ams from './en-US/ams';
import bol from './en-US/bol';
import common from './en-US/common';
import workflow from './en-US/workflow';
import admin from './en-US/admin';
import dashboard from './en-US/dashboard';
import report from './en-US/report';
import inbox from './en-US/inbox';
import container from './en-US/container';
import packageUnit from './en-US/package';
import memos from './en-US/memos';
import approval from './en-US/approval';
import document from './en-US/document';
import booking from './en-US/booking';
import apiError from './en-US/api_error';
import chargeitem from './en-US/chargeitem';
import search from './en-US/search';
import fapiao from './en-US/fapiao';
import notification from './en-US/notification';
import quotation from './en-US/quotation';
import calendar from './en-US/calendar';
import { create } from 'lodash';

export default {
  'menu.dashboard': 'Home',
  'menu.ocean_import_shipments': 'Ocean Import',
  'menu.ocean_import_shipments.ocean_import_shipments_list': 'Ocean Import Shipment List',
  'menu.ocean_import_shipments.my_ocean_import_shipments': 'My Ocean Import Shipments',
  'menu.ocean_import_shipments.ocean_import_mbls_list': 'Ocean Import MB/L List',
  'menu.ocean_import_shipments.ocean_import_hbls_list': 'Ocean Import HB/L List',
  'menu.ocean_export_shipments': 'Ocean Export',
  'menu.ocean_export_shipments.ocean_export_shipments_list': 'Ocean Export Shipment List',
  'menu.ocean_export_shipments.my_ocean_export_shipments': 'My Ocean Export Shipments',
  'menu.ocean_export_shipments.ocean_export_mbls_list': 'Ocean Export MB/L List',
  'menu.ocean_export_shipments.ocean_export_hbls_list': 'Ocean Export HB/L List',
  'menu.ocean_export.containers': 'Containers',
  'menu.ocean_import.containers': 'Containers',
  'menu.ocean_import_shipments.ocean_import_container_list': 'Container Tracking',
  'menu.ocean_export_shipments.ocean_export_container_list': 'Container Tracking',
  'menu.air_import_shipments': 'Air Import',
  'menu.air_import_shipments.air_import_shipments_list': 'Air Import Shipment List',
  'menu.air_import_shipments.my_air_import_shipments': 'My Air Import Shipments',
  'menu.air_import_shipments.air_import_mawbs_list': 'Air Import MAWB List',
  'menu.air_import_shipments.air_import_hawbs_list': 'Air Import HAWB List',
  'menu.air_export_shipments': 'Air Export',
  'menu.air_export_shipments.air_export_shipments_list': 'Air Export Shipment List',
  'menu.air_export_shipments.my_air_export_shipments': 'My Air Export Shipments',
  'menu.air_export_shipments.air_export_mawbs_list': 'Air Export MAWB List',
  'menu.air_export_shipments.air_export_hawbs_list': 'Air Export HAWB List',
  'menu.customer': 'Customer',
  'menu.customer.shipments': 'Shipments',
  'menu.customer.shipments.ocean_import': 'Ocean Import',
  'menu.customer.shipments.ocean_export': 'Ocean Export',
  'menu.customer.shipments.air_import': 'Air Import',
  'menu.customer.shipments.air_export': 'Air Export',
  'menu.customer.shipments.misc': 'Misc',
  'menu.customer.shipments.truck': 'Truck',
  'menu.misc_shipments': 'Misc',
  'menu.truck_shipments': 'Truck',
  'menu.misc_shipments.my_misc_shipments': 'My Misc Shipments',
  'menu.truck_shipments.my_truck_shipments': 'My Truck List',
  'menu.shipments': 'Shipments',
  'menu.shipments.shipments_list': 'Shipment List',
  'menu.shipments.my_shipments': 'My Shipments',
  'menu.shipments.all.my_shipments': 'My Shipments',
  'menu.shipments.mbls_list': 'MB/L List',
  'menu.shipments.all.mbls_list': 'MB/L List',
  'menu.shipments.hbls_list': 'HB/L List',
  'menu.shipments.all.hbls_list': 'HB/L List',
  'menu.bookings': 'Bookings',
  'menu.bookings.ocean_export': 'Ocean Export',
  'menu.bookings.air_export': 'Air Export',
  'menu.air_import_shipments.air_import_new': 'New Shipment',
  'menu.air_export_shipments.air_export_new': 'New Shipment',
  'menu.ocean_export_shipments.ocean_export_new': 'New Shipment',
  'menu.ocean_import_shipments.ocean_import_new': 'New Shipment',
  'menu.ocean_import_shipments.ocean_import_edi': 'Ocean Import EDI List',
  'menu.air_import_shipments.air_import_edi': 'Air Import EDI List',
  'menu.shipments.ocean_import.edi': 'Ocean Import EDI List',
  'menu.shipments.air_import.edi': 'Air Import EDI List',
  'menu.shipments.new': 'New Shipment',
  'menu.ai': 'AI',
  'menu.isf': 'ISF',
  'menu.isf.isf_new': 'New ISF',
  'menu.isfs.new': 'New ISF',
  'menu.isf.isf_list': 'ISF List',
  'menu.contacts': 'Contacts',
  'menu.contacts.new': 'New Contacts',
  'menu.contacts.contacts_list': 'Contact List',
  'menu.contacts.contacts_new': 'New Contact',
  'menu.accounting': 'Accounting',
  'menu.accounting.ar_ap': 'AR/AP',
  'menu.accounting.invoices': 'Receivables',
  'menu.accounting.pending_bills': 'Payables',
  'menu.accounting.received_invoices': 'Received Invoices',
  'menu.accounting.all_invoices': 'All Invoice',
  'menu.accounting.invoices/pending_bills': 'Receivables/Payables',
  'menu.accounting.statements': 'Statements',
  'menu.accounting.statements.open': 'Open Statements',
  'menu.accounting.payment_plans': 'Payment Plans',
  'menu.accounting.payment_plans_list': 'Payment Plan List',
  'menu.accounting.incoming_payments': 'Transactions',
  'menu.accounting.credit_notes': 'Credit Notes',
  'menu.accounting.debit_notes': 'Debit Notes',
  'menu.accounting.credit_notes/debit_notes': 'Credit Notes/Debit Notes',
  'menu.accounting.charge_item_codes': 'Charge Item Codes',
  'menu.accounting.charge_item_templates': 'Charge Item Templates',
  'menu.accounting.tax_codes': 'Tax Codes',
  'menu.accounting.accounts': 'Accounts',
  'menu.accounting.journals.new': 'New Journal Entry',
  'menu.accounting.journals': 'General Journal',
  'menu.accounting.journals.list': 'General Journal List',
  'menu.accounting.locks': 'Lock/Unlock',
  'menu.accounting.currency_rates': 'Currency Rates',
  'menu.accounting.transactions': 'Transactions',
  'menu.accounting.transactions.incoming_payments': 'Incoming Transactions',
  'menu.accounting.transactions.incoming_payments.new': 'New Incoming Transaction',
  'menu.accounting.transactions.payments': 'Outgoing Payments',
  'menu.accounting.transactions.payments.new': 'New Payment Transaction',
  'menu.accounting.transactions.bank_reconciliation': 'Bank Reconciliation',
  'menu.accounting.transactions.deposits': 'Deposits',
  'menu.accounting.accounting_reports': 'Reports',
  'menu.accounting.account_aging': 'Account Aging',
  'menu.accounting.account_receivables_aging': 'Account Receivables Aging',
  'menu.accounting.account_payables_aging': 'Account Payables Aging',
  'menu.accounting.account_statement': 'Account Statement Report',
  'menu.accounting.shipments_pnl': 'Shipments P&L Report',
  'menu.accounting.account_balance': 'Account Balance Report',
  'menu.accounting.balance_sheet': 'Balance Sheet',
  'menu.accounting.income_statement': 'Income Statement',
  'menu.accounting.cash_flow_statement': 'Cash Flow Statement',
  'menu.accounting.journal': 'Journal Report',
  'menu.accounting.revenue': 'Revenue Report',
  'menu.accounting.gl': 'General Ledger Report',
  'menu.accounting.daily_cash': 'Daily Cash Report',
  'menu.accounting.current_account': 'Current Account Report',
  'menu.accounting.tax_report': 'Tax Report',
  'menu.accounting.fapiao': 'Fa Piao',
  'menu.reports': 'Reports',
  'menu.reports.account_receivables_aging': 'Account Aging',
  'menu.accounting.statements.new': 'New Statement',
  'menu.accounting.statements.my': 'My Statements',
  'menu.accounting.statements.list': 'Statement List',
  'menu.accounting.payment_plans.new': 'New Payment Plan',

  'menu.reports.account_payables_aging': 'Account Payables Aging',
  'menu.reports.account_statement': 'Account Statement Report',
  'menu.reports.shipments_pnl': 'Shipments P&L Report',
  'menu.reports.shipments_pnl.ocean_export': 'Ocean Export',
  'menu.reports.shipments_pnl.ocean_import': 'Ocean Import',
  'menu.reports.shipments_pnl.air_export': 'Air Export',
  'menu.reports.shipments_pnl.air_import': 'Air Import',
  'menu.reports.shipments_pnl.misc': 'Misc',
  'menu.reports.shipments_pnl.truck': 'Truck',
  'menu.reports.account_balance': 'Account Balance Report',
  'menu.reports.balance_sheet': 'Balance Sheet',
  'menu.reports.income_statement': 'Income Statement',
  'menu.reports.cash_flow_statement': 'Cash Flow Statement',
  'menu.reports.journal': 'Journal Report',
  'menu.reports.revenue': 'Revenue Report',
  'menu.reports.shipments': 'Shipments',
  'menu.reports.shipments_chart': 'Shipments Chart',
  'menu.reports.shipments_report': 'Shipments Report',
  'menu.reports.shipment_sales_report': 'Shipment Sales Report',
  'menu.reports.employee': 'Employee',
  'menu.reports.gl': 'General Ledger Report',
  'menu.reports.daily_cash': 'Daily Cash Report',
  'menu.reports.current_account': 'Current Account Report',
  'menu.reports.tax_report': 'Tax Report',
  'menu.reports.shipment_milestone_report': 'Shipment Milestone Report',
  'menu.admin': 'Admin',
  'menu.accounting.bills': 'Payables (Bills)',
  'menu.accounting.bills.new': 'New Payable (Bill)',
  'menu.accounting.bills.pending': 'Outstanding Payables',
  'menu.accounting.bills.daily_pending': 'Daily Payables',
  'menu.accounting.bills.paid': 'Paid Payables',
  'menu.accounting.year_end_closing': 'Year End Closing',
  'menu.admin.accounting_setting': 'Accounting Setting',
  'menu.admin.charge_item_codes': 'Charge Item Codes',
  'menu.admin.tax_codes': 'Tax Codes',
  'menu.admin.gl_codes': 'G/L Codes',
  'menu.admin.charge_item_templates': 'Charge Item Templates',
  'menu.admin.accounts': 'Accounts',
  'menu.admin.currency_rates': 'Currency Rates',
  'menu.admin.users': 'Manage Employee',
  'menu.admin.approval': 'Approval Center Settings',
  'menu.admin.colors': 'Colors',
  'menu.admin.tracking_users': 'Tracking Users',
  'menu.admin.ams_agents': 'AMS Agents',
  'menu.shipments.air_import': 'Air Import',
  'menu.shipments.air_import.new': 'New',
  'menu.shipments.air_export.new': 'New',
  'menu.shipments.air_export': 'Air Export',
  'menu.shipments.misc': 'Operation List',
  'menu.shipments.truck': 'Truck List',
  'menu.shipments.misc.new': 'New Operation',
  'menu.shipments.truck.new': 'New Operation',
  'menu.misc_shipments.new_operation': 'New Operation',
  'menu.truck_shipments.new_operation': 'New Truck',
  'menu.truck_shipments.new_truck': 'New Truck',
  'menu.misc_shipments.operation_list': 'Operation List',
  'menu.truck_shipments.operation_list': 'Truck List',
  'menu.truck_shipments.truck_list': 'Truck List',
  'menu.shipments.ocean_export.new': 'New',
  'menu.shipments.ocean_export': 'Ocean Export',
  'menu.shipments.ocean_import.new': 'New',
  'menu.shipments.ocean_import': 'Ocean Import',
  'menu.shipments.ocean_import.mbls': 'MB/L',
  'menu.shipments.ocean_import.hbls': 'HB/L',
  'menu.shipments.ocean_export.mbls': 'MB/L',
  'menu.shipments.ocean_export.hbls': 'HB/L',
  'menu.shipments.air_import.mawbs': 'MAWB',
  'menu.shipments.air_import.hawbs': 'HAWB',
  'menu.shipments.air_export.mawbs': 'MAWB',
  'menu.shipments.air_export.hawbs': 'HAWB',
  'menu.shipments.ocean_import.shipments': 'Ocean Import Shipments',
  'menu.shipments.ocean_import.containers': 'Containers Tracking',
  'menu.shipments.ocean_export.containers': 'Containers Tracking',
  'menu.shipments.ocean_export.shipments': 'Ocean Export Shipments',
  'menu.shipments.air_import.shipments': 'Air Import Shipments',
  'menu.shipments.air_export.shipments': 'Air Export Shipments',
  'menu.shipments.misc.shipments': 'Misc Shipments',
  'menu.shipments.truck.shipments': 'Truck Shipments',
  'menu.shipments.ocean_import.my_shipments': 'My Ocean Import Shipments',
  'menu.shipments.ocean_export.my_shipments': 'My Ocean Export Shipments',
  'menu.shipments.air_import.my_shipments': 'My Air Import Shipments',
  'menu.shipments.air_export.my_shipments': 'My Air Export Shipments',
  'menu.shipments.misc.my_shipments': 'My Misc Shipments',
  'menu.shipments.truck.my_shipments': 'My Truck Shipments',
  'menu.air_export_shipments.air_export_new_booking': 'New Booking',
  'menu.ocean_export_shipments.ocean_export_new_booking': 'New Booking',
  'menu.air_export_shipments.air_export_booking_list': 'Booking List',
  'menu.ocean_export_shipments.ocean_export_booking_list': 'Booking List',
  'menu.shipments.ocean_export.duplicate': 'Duplicate',
  'menu.shipments.ocean_import.duplicate': 'Duplicate',
  'menu.shipments.air_export.duplicate': 'Duplicate',
  'menu.shipments.air_import.duplicate': 'Duplicate',
  'menu.shipments.misc.duplicate': 'Duplicate',
  'menu.shipments.truck.duplicate': 'Duplicate',

  'menu.approvals': 'Approval Center',
  'menu.admin.packages': 'Package Unit',
  'menu.admin.containers': 'Container TP/SZ',
  'menu.admin.employee': 'Employee Report',
  'menu.admin.workflows': 'Workflows',
  'menu.ams': 'AMS',
  'menu.ams.ams_new': 'New Manifest',
  'menu.ams.new': 'New Manifest',
  'menu.ams.ams_list': 'AMS List',
  'menu.isfs': 'ISFs',
  'menu.isfs.list': 'New ISF',
  'menu.bol': 'Bill Of Lading',
  'menu.ams.ams_bol': 'Bill Of Lading',
  'menu.aci.aci_bol': 'Bill Of Lading',
  'menu.ams_bol': 'Bill Of Lading',
  'menu.aci_bol': 'Bill Of Lading',
  'menu.ams.bol_new': 'New',
  'menu.ams_bol.new': 'New',
  'menu.ams.bol_list': 'Search',
  'menu.bol.bol_new': 'New Bol',
  'menu.bol.bol_list': 'Bol List',
  'menu.aci': 'ACI',
  'menu.aci.new': 'New Manifest',
  'menu.aci.aci_new': 'New Manifest',
  'menu.aci.aci_list': 'ACI List',
  'menu.sales.quotations': 'Quotations',
  'menu.sales': 'Sales',
  'menu.ai.inbox': 'AI Inbox',
  'menu.qtoshipments': 'Quotation Shipment Requests',
  'menu.quotations': 'Quotations',
  'menu.sales.qtoshipments': 'Quotation Shipment Requests',
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.home.introduce': 'introduce',
  search: 'Search',
  'update.saved': 'Update saved.',
  'upload.saved': 'Upload saved.',
  send: 'Send',
  reset: 'Reset',
  'see all': 'See All',
  edit: 'Edit',
  save: 'Save',
  next: 'Next',
  all: 'All',
  cancel: 'Cancel',
  disable: 'Disable',
  enable: 'Enable',
  create: 'Create',
  update: 'Update',
  approved: 'Approved',
  approve: 'Approve',
  approve_all: 'Approve all',
  delete_all: 'Delete all',
  delete: 'Delete',
  est: 'Est.',
  act: 'Act.',
  ar_ap: 'AR/AP',
  statement: 'Statement',
  publish: 'Publish',
  unpublish: 'Unpublish',
  download: 'Download',
  close: 'Close',
  duplicate: 'Duplicate',
  transfer: 'Transfer To',
  move: 'Move To',
  hide: 'Hide',
  date: 'Date',
  filter: 'Filter',
  lock: 'Lock',
  unlock: 'Unlock',
  changelog: 'ChangeLog',
  submit: 'Submit',
  unsubmit: 'Unsubmit',
  submitted: 'Submitted',
  action: 'Action',
  refresh: 'Refresh',
  assign: 'Assign',
  clear: 'Clear',
  clear_paid: 'Clear Paid',
  clear_in_statement: 'Clear in Statement',
  discard: 'Discard',
  expand_all: 'Expand All',
  collapse_all: 'Collapse All',
  detail: 'detail',
  no: 'No',
  debit: 'Debit',
  credit: 'Credit',
  amount: 'Amount',
  confirm: 'Confirm',
  total: 'Total',
  logout: 'Logout',
  on: 'On',
  off: 'Off',
  ok: 'OK',
  upload: 'Upload',
  voided: 'Voided',
  void: 'Void',
  pending: 'Pending',
  reconcile: 'Reconcile',
  reconciled: 'Reconciled',
  unreconciled: 'Unreconciled',
  not_submitted: 'Not Submitted',
  open: 'Open',
  draft: 'Draft',
  paid: 'Paid',
  received: 'Settled',
  partially_received: 'Partially Settled',
  email: 'Email',
  add: 'Add',
  archived: 'Archived',
  archive: 'Archive',
  proceed: 'Proceed',
  un_achive: 'Unarchive',
  preview: 'Preview',
  view: 'View',
  load_more: 'Load More',
  save_as_draft: 'Save as Draft',
  generate: 'Generate',
  re_generate: 'Re-generate',
  information: 'Information',
  updated_at: 'Updated At',
  created_at: 'Created At',
  mbl: 'MBL',
  hbl: 'HBL',
  kgs: 'KGS',
  tel: 'Tel',
  fax: 'Fax',
  attn: 'Attn',
  re_generate_pdf: 'Re-generate PDF',
  partially_paid: 'Partially Paid',
  'un_save_notice.title': 'You have unsaved changes.',
  'un_save_notice.content': 'Are you sure to discard the changes?',
  all_done: 'All done!',
  'profile.title.user_profile': 'User Profile',
  locked: 'Locked',
  yes: 'Yes',
  actions: 'Actions',
  apply: 'Apply',
  type: 'Type',
  balance: 'Balance',
  required: 'Required',
  currency: 'Currency',
  amount_in_currency: 'Amount ({currency})',
  balance_in_currency: 'Balance ({currency})',
  pending_in_currency: 'Pending ({currency})',
  received_in_currency: 'Received ({currency})',
  accounting_pending: 'Pending',
  accoutning_pending_in_currency: 'Pending ({currency})',
  please_confirm: 'Please confirm',
  unreconcile: 'Unreconcile',
  office: 'Office',
  in_offices: 'In Offices',
  code: 'Code',
  tax: 'Tax',
  enabled: 'Enabled',
  blocked: 'Blocked',
  blocked_login: 'Blocked login',
  include_drafts: 'Include Drafts',
  push_edi: 'SmartForwarder EDI',
  from_date: 'From Date',
  to_date: 'To Date',
  container_tracking: 'Container Tracking',
  hts_code: 'HTS Code',
  user: 'User',
  'profile.setting.update.info': 'Update Info',
  'profile.setting.update.password': 'Change Password',
  'profile.setting.update_email_password': 'Update Email Password',
  'profile.title.email': 'Email',
  'profile.title.permissions': 'Permissions',
  'profile.title.password.new': 'New Password',
  'profile.title.password.confirm': 'Confirm Password',
  'profile.title.password.current': 'Current Password',
  'employee.field.wechat': 'Wechat',
  'employee.field.qq': 'QQ',
  'employee.field.alternative_emails': 'Alternative Emails',
  'employee.field.phone': 'Phone',
  'profile.email.setting.smtp.title': 'Email Config',
  'profile.email.field.pass': 'SMTP Password',
  'profile.placeholder.pass': 'Please enter SMTP password',
  'profile.email.setting.verify.success': 'Verify SMTP successfully',
  'profile.email.setting.verify.failed': 'Verify SMTP failed',
  'profile.setting.update.success': 'Info updated successfully',
  'profile.setting.user.agreement.title': 'Trial User Agreement',
  'profile.setting.smtp.verify.success': 'Verify SMTP successfully',
  'profile.setting.smtp.verify.failed': 'Verify SMTP failed',
  'profile.placeholder.email': 'Enter email',
  'profile.placeholder.input': 'Please enter',
  'profile.placeholder.search': 'Please search',
  'profile.placeholder.select': 'Please select',
  'profile.placeholder.select.date_range': 'Please select a date range',
  'profile.placeholder.password.new': 'Enter new password',
  'profile.placeholder.password.confirm': 'Confirm new password',
  'profile.placeholder.password.current': 'Enter current password',
  'profile.error.password.confirm': 'Please confirm new password',
  'profile.error.password.mismatch': 'The two new passwords that you entered do not match!',
  'profile.setting.language': 'Language',
  'profile.setting.office': 'Office',
  'home.todos': 'Todos',
  'home.unassigned.shipments': 'Unassigned Shipments',
  'home.my_shipment_drafts': 'My Shipment Drafts',
  'transactions.document.type.transaction_voucher': 'Transaction Voucher',
  'transactions.document.type.transaction_info': 'Transaction Info',
  loading: 'Loading...',
  make_as_read: 'Mark as read',
  make_as_unread: 'Mark as unread',
  make_as_done: 'Mark as done',
  default: 'Default',
  others: 'Others',
  ...login,
  ...signup,
  ...shipment,
  ...contact,
  ...shipment_changes,
  ...contact_changes,
  ...transaction_changes,
  ...accounting,
  ...isf,
  ...ams,
  ...bol,
  ...common,
  ...workflow,
  ...admin,
  ...dashboard,
  ...report,
  ...inbox,
  ...container,
  ...packageUnit,
  ...memos,
  ...approval,
  ...document,
  ...chargeitem,
  ...booking,
  ...search,
  ...fapiao,
  'message.error.required': 'Please input ${label}',
  'error.no_shop_role': 'You don’t have permission for this page.',
  ...notification,
  ...apiError,
  ...quotation,
  ...calendar,
  'socket.isEditing': '{name} is editing',
  'importer.csvimporter.upload': 'Upload',
  'importer.csvimporter.select_header': 'Select Header',
  'importer.csvimporter.map_columns': 'Map Columns',
  'importer.csvimporter.expected_column': 'Expected Column',
  'importer.csvimporter.required': 'Required',
  'importer.csvimporter.drop_file': 'Drop your file here',
  'importer.csvimporter.or': 'or',
  'importer.csvimporter.browse_files': 'Browse files',
  'importer.csvimporter.download_template': 'Download Template',
  'importer.csvimporter.only_format_allow': 'Only CSV, XLS, and XLSX files can be uploaded',
  'importer.csvimporter.select_header_row': 'Select Header Row',
  'importer.csvimporter.select_row': 'Select the row which contains the column headers',
  'importer.csvimporter.only_first':
    'Only the first sheet (&quot;{{sheet}}&quot;) of the Excel file will be imported. To import multiple sheets, please upload each sheet individually.',
  'importer.csvimporter.cancel': 'Cancel',
  'importer.csvimporter.continue': 'Continue',
  'importer.csvimporter.your_file_column': 'Your File Column',
  'importer.csvimporter.your_sample_data': 'Your Sample Data',
  'importer.csvimporter.destination_column': 'Destination Column',
  'importer.csvimporter.include': 'Include',
  'importer.csvimporter.submit': 'Submit',
  'importer.csvimporter.loading': 'Loading...',
  'importer.csvimporter.please_include_all': 'Please include all required columns',
  'importer.csvimporter.back': 'Back',
  'importer.csvimporter.select_one': '- Select one -',
  'importer.csvimporter.empty': '- empty -',
  'importer.csvimporter.import_successful': 'Import Successful',
  'importer.csvimporter.upload_successful': 'Upload Successful',
  'importer.csvimporter.done': 'Done',
  'importer.csvimporter.upload another file': '上传另一个文件',
  'import.processing.title': 'Processing import...',
  'import.finished.title': 'Import successful!',
  'import.finished.description': 'Successful imports: {success}\nFailed imports: {error}',
};
