export default {
  'quotation.create': 'Create Quotation',
  'quotation.created.success': 'Successfully Created Quotation',
  'quotation.detail': 'Quotation Detail',
  'quotation.field.title': 'Title',
  'quotation.field.shipment_type': 'Shipment Type',
  'quotation.field.code': 'Code',
  'quotation.field.type': 'Type',
  'quotation.field.customer': 'Customer',
  'quotation.field.description': 'Description',
  'quotation.field.pol': 'POL',
  'quotation.field.pod': 'POD',
  'quotation.field.place_of_delivery': 'Place Of Delivery',
  'quotation.field.status': 'Status',
  'quotation.field.issue_date': 'Issue Date',
  'quotation.field.expire_date': 'Expire Date',
  'quotation.field.created_at': 'Created At',
  'quotation.field.terms_and_conditions': 'Terms and Conditions',
  'quotation.total_price': 'Total Price',
  'quotation.field.sales': 'Sales',
  'quotation.total_cost': 'Total Cost',
  'quotation.profit': 'Profit',
  'quotation.list.title': 'Quotation List',
  'quotation.deleted.success': 'Quotation deleted successfully',
  'quotation.deleted.title': 'Are you sure delete this quotation?',
  'quotation.deleted.cannot_undo': 'This action cannot be undone.',
  'quotation.deleted.cancel': 'Deletion cancelled',
  'quotation.report': 'Print/Email Quotation',
  'quotation.charge_item_group.freight_charge': 'Freight Charges',
  'quotation.charge_item_group.destination_charge': 'Destination Charges',
  'quotation.title.basic_information': 'Basic Information',
  'quotation.title.routes': 'Routes',
  'quotation.title.charge_items': 'Charge Items',
  'quotation.submit.confirm': 'Are you sure to submit this quotation?',
  'quotation.publish.confirm': 'Are you sure to publish this quotation?',
  'quotation.unsubmit.confirm': 'Are you sure to unsubmit this quotation?',
  'quotation.unpublish.confirm': 'Are you sure to unpublish this quotation?',
  'quotation.create_qtoshipment': 'Create Shipment Request',
  'qtoshipment.create': 'Create Shipment Request',
  'qtoshipment.detail': 'Shipment Request Detail',
  'quotation.title.costs': 'Costs',
  'quotation_report.pdf.to': 'TO',
  'quotation_report.pdf.date': 'DATE',
  'quotation_report.pdf.code': 'QUOTE NO',
  'quotation_report.pdf.expire_date': 'VALIDITY',
  'quotation_report.pdf.sales': 'SALES',
  'quotation_report.pdf.attn': 'ATTN',
  'quotation_report.pdf.show_main_currency': 'Show Totals in Main Currency',
  'quotation_report.pdf.show_currency_rate': 'Currency Rate',
  'quotation_report.pdf.currency_rates': 'Currency Rates',
  'quotation_report.pdf.show_terms': 'Show Terms and Conditions',
  'quotation_report.pdf.port_of_loading': 'ORIGIN PORT',
  'quotation_report.pdf.port_of_discharge': 'DESTINATION PORT',
  'quotation_report.pdf.place_of_receipt': 'Place of Receipt',
  'quotation_report.pdf.place_of_delivery': 'Place of Delivery',
  'quotation_report.pdf.port_of_transit': 'PORT OF TRANSIT',
  'quotation_report.pdf.index': '#',
  'quotation_report.pdf.code_description': 'ITEM',
  'quotation_report.pdf.description': 'DESCRIPTION',
  'quotation_report.pdf.local_description': 'LOCAL DESCRIPTION',
  'quotation_report.pdf.currency': 'CUR.',
  'quotation_report.pdf.unit': 'UNIT',
  'quotation_report.pdf.vol': 'VOL.',
  'quotation_report.pdf.min': 'MIN.',
  'quotation_report.pdf.rate': 'RATE',
  'quotation_report.pdf.vat': 'VAT',
  'quotation_report.pdf.sst': 'SST',
  'quotation_report.pdf.gst': 'GST',
  'quotation_report.pdf.sst_amount': 'SST AMT',
  'quotation_report.pdf.gst_amount': 'GST AMT',
  'quotation_report.pdf.wht': 'WHT',
  'quotation_report.pdf.amount': 'AMOUNT',
  'quotation_report.pdf.ihh7hli1ir1': 'OPTION',
  'quotation_report.pdf.option': 'OPTION',
};
