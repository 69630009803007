export default {
  'container.field.container': 'Container',
  'container.field.seal_number': 'Seal No.',
  'container.field.pieces': 'Pieces',
  'container.field.name': 'Container Number',
  'container.field.weightUnit': 'Weight Unit',
  'container.field.volumeUnit': 'Volume Unit',
  'container.field.size': 'Size',
  'container.field.type': 'Type',
  'container.field.sz_tp': 'SZ/TP',
  'container.field.weight': 'Weight',
  'container.field.cbm': 'CBM',
  'container.field.volume': 'Volume',
  'container.field.soc': 'SOC',
  'container.field.discharged_date': 'Discharged Date',
  'container.field.return_date': 'Empty Return',
  'container.field.return_date.required': 'Please input Empty Return',
  'container.field.marks': 'Marks',
  'container.field.location': 'Location',
  'container.field.moves': 'Moves',
  'container.field.vessel_voyage': 'Vessel/Voyage',
  'container.field.gatein_date': 'Gate In Date',
  'container.field.it_number': 'IT No.',
  'container.field.rail_bl': 'Rail BL',
  'container.field.pickup_number': 'Pickup No.',
  'container.field.move_date': 'Move Date',
  'container.field.pickup_date': 'Pickup Date',
  'container.field.pickup_date.required': 'Please input Pickup Date',
  'container.field.last_free_date': 'Last Free Date',
  'container.field.rail_date': 'Rail Date',
  'container.field.delivery_date': 'ATA Door',
  'container.field.iso_container_type': 'Container Type',
  'container.field.code': 'Code',
  'container.field.description': 'Description',
  'container.field.ams_type': 'AMS Type',
  'container.field.group_code': 'Category',
  'container.field.teu': 'TEU',
  'container.field.is_active': 'Active',
  'container.field.mark': 'Mark',
  'container.field.country_origin': 'Country Origin',
  'container.field.cost': 'Cost',
  'container.field.has_tracking': 'Enabled Tracking',
  'container.field.commercial_value': 'Commercial Value',
  'container.delete.confirm': 'Delete this container?',
  'container.empty': 'No Containers',
  'container.tp/sz.empty': 'No Containers TP/SZ',
  'container.deletePopconfirmMessage': 'Delete this container?',
  'container.onlyOneLineEditorAlertMessage': 'Only one container can be edited at a time',
  'container.onlyAddOneLineAlertMessage': 'Only one container can be edited at a time',
  'container.add_container': 'Add Container',
  'container.no_moves': 'No Moves',
  'container.container_moves': 'Container Moves',
  'container.copy_mbl': 'Copy containers from MB/L',
  'container.copy_to_hbl': 'Copy container to HB/L',
  'container.add_multi': 'Add Multiple',
  'container.invalid.format': 'Invalid container number format',
  'container.field.milestone': 'Milestone',
  'container.field.progress_tracking': 'Progress Tracking',
  'container.list_title.list': 'Container List',
  'container.sync_from_hbls': 'Sync from HB/Ls',
  'container.settings.trace_update': 'Update Trace',
  'container.field.name.syncing': '{name} (syncing)',
  'container.field.is_available_for_pickup': 'Available for Pickup',
  'container.field.unload_from_vessel': 'Unload From Vessel',
  'container.field.yard_location': 'Yard Location',
  'container.field.is_carrier_release': 'Carrier Released',
  'container.settings.tracking_update': 'Update Tracking',
  'container.tracking.data_source': 'Data Source',
  'container.tracking.inland_origin': 'Inland Origin',
  'container.tracking.origin_port': 'Origin Port',
  'container.tracking.destination_port': 'Destination Port',
  'container.tracking.inland_destination': 'Inland Destination',
  'container.tracking.milestones': 'Milestones',
  'container.tracking.vessel_info': 'Vessel Info',
  'container.tracking.location': 'Location',
  'container.tracking.final_dest_eta': 'Final Destination ETA',
  'container.tracking.custom_clearance_date': 'Custom Clearance Date',
  'container.tracking.empty_return': 'Empty Return',
  'container.tracking.gate_out': 'Gate Out',
  'container.tracking.unload_from_vessel': 'Unload From Vessel',
  'container.tracking.enable_tracking': 'Enable Tracking',
  'container.tracking.disable_tracking': 'Disable Tracking',
  'container.tracking.estimated': 'ESTIMATED',
  'container.tracking.remark': 'Internal Remarks',
  'container.tracking.new_remark': 'Remarks',
  'container.tracking.internal_remark': 'Internal Remarks',
  'container.field.free_detention_date': 'FDD',
  'container.field.delivery_eta': 'ETA Door',
  'common.completed': 'Completed',
  'common.update.failed': 'Update failed',
  'container.tracking.completed': 'Completed',
  'container.tracking.in_progress': 'In Progress',
  'container.field.status': 'Stages',
  'container.status.progress': '{completed}/{total}', // Added for progress display
  'container.tracking.check_vessel_unload': 'Check the vessel unloading',
  'container.tracking.final_eta': 'Final ETA',
  'container.field.size_type': 'TP/SZ',
  'container.tracking.field.pickup_number': 'Pick No.',
  'container.tracking.field.arrival_notice_sent_date': 'A/N',
  'container.tracking.field.delivery_order_sent_date': 'D/O',
  'container.tracking.field.place_of_delivery': 'DEL',
  'container.tracking.field.term': 'Mode Type',
  'container.tracking.field.chassis_days': 'Chassis Days',
  'container.field.chassis_days.tooltip': 'Chassis Days: {return_date} - {gate_out} + 1 day',
  'container.field.free_detention_date.tooltip': 'FDD: {gate_out} + 4 days',
  'container.tracking.textarea.placeholder': 'Press Shift + Enter for new line, Enter to save',
  'container.tracking.status.overdue': '{count} Overdue',
  'container.tracking.status.pending_pickup': '{count} Pending Pickup',
};
