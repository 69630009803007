import { DocumentTypes } from './type';

export const OceanPublishRequiredFields = [
  'hbl_no',
  'mbl_no',
  'port_of_loading',
  'port_of_discharge',
  'mode',
  'final_port_of_discharge',
  'term',
  'etd',
  'eta',
  'shipper',
  'vessel',
  'voyage',
  'consignee',
  'notify',
  'carrier',
  'commodity_info',
  'final_destination',
  'pieces',
  'weight',
  'measure',
  'shipment_containers',
  'shipment_containers.name',
  'shipment_containers.seal_number',
  'shipment_containers.size',
  'shipment_containers.type',
  'shipment_containers.weight',
  'shipment_containers.measure',
  'mbl_type',
  'carrier',
  'agent',
  'coloader',
  'agent_ref_no',
  'operator',
  'vessel',
  'voyage',
  'freight_payment',
  'mode',
  'term',
  'obl_type',
  'bill_to',
  'hbl_release',
  'sales_type',
];

export const OceanOtherPublishRequiredFields = ['mbl_no', 'eta'];
export const BookingRequiredFields = ['booking_no', 'booking_date'];

export const ShipmentTypeEnum = {
  ocean_import: 'Ocean Import',
  ocean_export: 'Ocean Export',
  air_import: 'Air Import',
  air_export: 'Air Export',
  misc: 'Misc.',
  truck: 'Truck',
};
export const ShipmentDateTypeEnum = {
  created_at: 'Created At',
  eta: 'ETA',
  etd: 'ETD',
};
export const ReportDateTypeEnum = {
  created_at: 'Created At',
  eta_etd: 'ETA/ETD',
};
export const ShipmentTypeSuffix = {
  ocean_import: 'oi',
  ocean_export: 'oe',
  air_import: 'ai',
  air_export: 'ae',
  misc: 'ms',
  truck: 'tk',
};

export const TruckPublishRequiredFields = [];

export const MiscPublishRequiredFields = [];

export const AirPublishRequiredFields = [
  'hbl_no',
  'mbl_no',
  'port_of_loading',
  'port_of_discharge',
  'mode',
  'final_port_of_discharge',
  'term',
  'etd',
  'eta',
  'shipper',
  'flight',
  'consignee',
  'notify',
  'carrier',
  'commodity',
  'final_destination',
  'pieces',
  'weight',
  'measure',
  'obl_type',
  'mbl_type',
  'carrier',
  'agent',
  'coloader',
  'agent_ref_no',
  'operator',
  'vessel',
  'voyage',
  'freight_payment',
  'mode',
  'bill_to',
  'hbl_release',
  'sales_type',
  'cargo_type',
];

export const DefaultTodos = [
  'accept_payment',
  'arrival_notice',
  'assign',
  'carrier_release',
  'confirm_carrier_released',
  'confirm_terminal_released',
  'confirm_payment',
  'container_pickup',
  'confirm_container_pickedup',
  'container_return',
  'confirm_container_returned',
  'container_return_free_date',
  'customs_clearance',
  'confirm_customs_released',
  'delivery_order',
  'confirm_delivery_order_received',
  'hbl',
  'it',
  'mbl',
  'moving',
  // 'pickup_last_free_date',
  'pickup_number',
  // 'release_order',
  // 'release_status',
  'terminal_free_date',
];

export const Air_Term = {
  AIRPORT: 'AR',
  CFS: 'CFS',
  CY: 'CY',
  DOOR: 'DOOR',
  FI: 'FI',
  FO: 'FO',
  FOT: 'FT',
  RAMP: 'RAMP',
  TACKLE: 'TK',
};

export const Ocean_Term = {
  BT: 'BT',
  CFS: 'CFS',
  CY: 'CY',
  DOOR: 'DOOR',
  FI: 'FI',
  FO: 'FO',
  FOT: 'FT',
  RAMP: 'RAMP',
  TACKLE: 'TK',
};

export const AgingReportIntervals = [
  { start: 11, end: 31 },
  { start: 31, end: 41 },
  { start: 41, end: 61 },
  { start: 61, end: 91 },
  { start: 91 },
  { start: 0 },
];

export const BLCommonFields = [
  'agent',
  'cy_location',
  'cfs_location',
  'operator',
  'final_destination',
  'final_eta',
  'place_of_receipt',
  'place_of_receipt_etd',
  'place_of_delivery',
  'place_of_delivery_eta',
  // removed according to Nichole
  // 'freight_payment',
  // 'term',
  'mode',
  // 'cy_location',、
  'agent_ref_no',
  'entry_date',
  'it_no',
  'it_place',
  'it_date',
  'cycfs_location',
  'scn',
  'sa_id',
  'sales_type',
  'isf_no',
  'ams_no',
  'sales',
  'operator',
];

export const MBL_MAPPING_TO_HBL = {
  shipment_number: 'hbl_no',
  cy_location: 'cycfs_location',
};

export const ContactFields = ['agent', 'cy_location', 'cfs_location'];

export const HiddenSidebarShipmentType = ['misc', 'truck'];
export const PaymentTypes = {
  ach: 'ACH',
  bill_pay: 'Bill Pay',
  cash: 'Cash',
  cargo_sprint: 'CargoSprint',
  check: 'Check',
  credit_card: 'Credit Card',
  e_check: 'E-Check',
  financial_reconcile: 'Financial Reconcile',
  offset: 'Offset',
  pay_cargo: 'PayCargo',
  wire: 'Wire',
  zelle: 'Zelle',
  debit_card: 'Debit Card',
  emodal: 'eModal',
  epic: 'Epic',
};

export const ReleaseMethodTypes = {
  original: 'ORIGINAL BILL OF LADING',
  telex: 'TELEX',
  seaway_bill: 'SEAWAY BILL',
  e_bill: 'E-BILL',
};

export const LimitedPaymentTypes = {
  cargo_sprint: 'CargoSprint',
  credit_card: 'Credit Card',
  pay_cargo: 'PayCargo',
};

export const PAGINATION_MAPPING = {
  total: 'totalCount',
  pageSize: 'pageSize',
  current: 'current',
};

export const SUGGESTION_DOCUMENT_TYPES = [
  DocumentTypes.carrier_arrival_notice,
  DocumentTypes.invoice,
  DocumentTypes.invoice_confirmation,
  DocumentTypes.receiving_notice,
  DocumentTypes.hbl,
  DocumentTypes.mbl,
  DocumentTypes.booking_confirmation,
  DocumentTypes.air_booking_confirmation,
  DocumentTypes.other,
];

export const EXCLUDE_DOCUMENT_TYPES_FOR_UPLOAD = [
  DocumentTypes.invoice_confirmation,
  DocumentTypes.air_arrival_notice,
  DocumentTypes.arrival_notice,
  DocumentTypes.delivery_order,
  DocumentTypes.delivery_order_customs,
  DocumentTypes.release_order,
  DocumentTypes.statement_group_by_shipment,
];

export const CONTAINER_TYPES = [
  {
    type: '22G0',
    name: '20 Standard Dry',
  },
  {
    type: '22H0',
    name: '20 Insulated',
  },
  {
    type: '22P1',
    name: '20 Flat Rack',
  },
  {
    type: '22P3',
    name: '20 Platform (collapsible)',
  },
  {
    type: '22R1',
    name: '20 Reefer',
  },
  {
    type: '22R9',
    name: '20 Reefer (non food)',
  },
  {
    type: '22T0',
    name: '20 Tank (non-dangerous)',
  },
  {
    type: '22T5',
    name: "20 Tank 8.5' (dangerous - 4 bar)",
  },
  {
    type: '22T6',
    name: '20 Tank (dangerous - 6 bar)',
  },
  {
    type: '22U0',
    name: '20 Open Top',
  },
  {
    type: '22U6',
    name: '20 Hard Top',
  },
  {
    type: '22V0',
    name: '20 Ventilated',
  },
  {
    type: '25G0',
    name: '20 High Cube',
  },
  {
    type: '25R1',
    name: '20 Reefer High Cube',
  },
  {
    type: '29P0',
    name: '20 Platform',
  },
  {
    type: '42G0',
    name: '40 Standard Dry',
  },
  {
    type: '42H0',
    name: '40 Insulated',
  },
  {
    type: '42P1',
    name: '40 Flat Rack',
  },
  {
    type: '42P3',
    name: '40 Platform (collapsible)',
  },
  {
    type: '42R1',
    name: '40 Reefer',
  },
  {
    type: '42R9',
    name: '40 Reefer (non food)',
  },
  {
    type: '42T0',
    name: '40 Tank',
  },
  {
    type: '42U0',
    name: '40 Open Top',
  },
  {
    type: '42U6',
    name: '40 Hard Top',
  },
  {
    type: '42V0',
    name: '40 Ventilated',
  },
  {
    type: '45G0',
    name: '40 High Cube',
  },
  {
    type: '45P3',
    name: '40 Platform High Cube',
  },
  {
    type: '45R1',
    name: '40 Reefer High Cube',
  },
  {
    type: '45R9',
    name: '40 Reefer High Cube (non food)',
  },
  {
    type: '45U6',
    name: '40 High Cube Hard Top',
  },
  {
    type: '49P0',
    name: '40 Platform',
  },
  {
    type: 'L5G0',
    name: '45 High Cube',
  },
  {
    type: 'L5R1',
    name: '45 Reefer High Cube',
  },
  {
    type: 'M2G0',
    name: '48 Ft',
  },
  {
    type: 'P2G0',
    name: '53 Ft',
  },
  {
    type: 'P5G0',
    name: '53 Ft HQ',
  },
];

export const PACKAGE_UNIT = [
  {
    code: '1B',
    name: 'Aluminium Drum',
  },
  {
    code: '1G',
    name: 'Fibre Drum',
  },
  {
    code: '1W',
    name: 'Wooden Drum',
  },
  {
    code: '4B',
    name: 'Aluminum Box',
  },
  {
    code: 'AMM',
    name: 'Ammo Pack',
  },
  {
    code: 'AMP',
    name: 'Ampoule',
  },
  {
    code: 'ATH',
    name: 'ATTACHMENT',
  },
  {
    code: 'BAG',
    name: 'Bag',
  },
  {
    code: 'BAL',
    name: 'Bale',
  },
  {
    code: 'BBL',
    name: 'Barrel',
  },
  {
    code: 'BD',
    name: 'Board',
  },
  {
    code: 'BDG',
    name: 'BANDING',
  },
  {
    code: 'BDL',
    name: 'Bundle',
  },
  {
    code: 'BEM',
    name: 'Beam',
  },
  {
    code: 'BH',
    name: 'Bunch',
  },
  {
    code: 'BIC',
    name: 'Bing Chest',
  },
  {
    code: 'BIN',
    name: 'Bin',
  },
  {
    code: 'BKG',
    name: 'BULK BAG',
  },
  {
    code: 'BLE',
    name: 'BALE',
  },
  {
    code: 'BLK',
    name: 'Bulk',
  },
  {
    code: 'BLT',
    name: 'BELTING',
  },
  {
    code: 'BN',
    name: 'Bale,non-compressed',
  },
  {
    code: 'BOB',
    name: 'Bobbin',
  },
  {
    code: 'BOT',
    name: 'Bottle',
  },
  {
    code: 'BOX',
    name: 'Box',
  },
  {
    code: 'BR',
    name: 'Bar',
  },
  {
    code: 'BRC',
    name: 'BRACING',
  },
  {
    code: 'BRG',
    name: 'Barge',
  },
  {
    code: 'BSK',
    name: 'Basket',
  },
  {
    code: 'BXI',
    name: 'Box with inner container',
  },
  {
    code: 'BXT',
    name: 'Bucket',
  },
  {
    code: 'CAB',
    name: 'Cabinet',
  },
  {
    code: 'CAG',
    name: 'Cage',
  },
  {
    code: 'CAN',
    name: 'Can',
  },
  {
    code: 'CAR',
    name: 'Carrier',
  },
  {
    code: 'CAS',
    name: 'Case',
  },
  {
    code: 'CBC',
    name: 'CONTAINERS OF BULK CARGO',
  },
  {
    code: 'CBY',
    name: 'Carboy',
  },
  {
    code: 'CC',
    name: 'Churn',
  },
  {
    code: 'CCS',
    name: 'Can Case',
  },
  {
    code: 'CE',
    name: 'Creel',
  },
  {
    code: 'CF',
    name: 'Coffer',
  },
  {
    code: 'CHE',
    name: 'Cheeses',
  },
  {
    code: 'CHS',
    name: 'Chest',
  },
  {
    code: 'CI',
    name: 'Canister',
  },
  {
    code: 'CJ',
    name: 'Coffin',
  },
  {
    code: 'CLD',
    name: 'Car Load, Rail',
  },
  {
    code: 'CNA',
    name: 'Household Good, Wood, Container',
  },
  {
    code: 'CNB',
    name: 'MILITARY AIRCRAFT CONTAINER',
  },
  {
    code: 'CNC',
    name: 'NAVY CARGO TRANSPORT CONTAINER',
  },
  {
    code: 'CND',
    name: 'COMMERCIAL HIGHWAY LIFT CONTAINER',
  },
  {
    code: 'CNE',
    name: 'ENGINE CONTAINER',
  },
  {
    code: 'CNF',
    name: 'MULTIWALL SECURED WAREHOUSE PALLET',
  },
  {
    code: 'CNT',
    name: 'Container',
  },
  {
    code: 'CNX',
    name: 'CONTAINER EXPRESS',
  },
  {
    code: 'COL',
    name: 'Coil',
  },
  {
    code: 'CON',
    name: 'Cones',
  },
  {
    code: 'COR',
    name: 'Core',
  },
  {
    code: 'CP',
    name: 'Carboy,protected',
  },
  {
    code: 'CRD',
    name: 'Cradle',
  },
  {
    code: 'CRF',
    name: 'CORNER REINFORCEMENT',
  },
  {
    code: 'CRT',
    name: 'Crate',
  },
  {
    code: 'CSK',
    name: 'Cask',
  },
  {
    code: 'CTN',
    name: 'Carton',
  },
  {
    code: 'CUB',
    name: 'Cube',
  },
  {
    code: 'CYL',
    name: 'Cylinder',
  },
  {
    code: 'DBK',
    name: 'Dry Bulk',
  },
  {
    code: 'DJ',
    name: 'Demijohn, Non-Protected',
  },
  {
    code: 'DP',
    name: 'Demijohn, Protected',
  },
  {
    code: 'DRK',
    name: 'Double Length Rack',
  },
  {
    code: 'DRM',
    name: 'Drum',
  },
  {
    code: 'DSK',
    name: 'Double Length Skid',
  },
  {
    code: 'DTB',
    name: 'Double Length Tote Bin',
  },
  {
    code: 'EGG',
    name: 'EGG CRATING',
  },
  {
    code: 'ENV',
    name: 'Envelope',
  },
  {
    code: 'EPR',
    name: 'EDGE PROTECTION',
  },
  {
    code: 'FIR',
    name: 'Firkin',
  },
  {
    code: 'FLO',
    name: 'Flo-bin',
  },
  {
    code: 'FLX',
    name: 'Bag, Liner Liquid',
  },
  {
    code: 'FO',
    name: 'Footlocker',
  },
  {
    code: 'FP',
    name: 'Filmpack',
  },
  {
    code: 'FRM',
    name: 'Frame',
  },
  {
    code: 'FSK',
    name: 'Flask',
  },
  {
    code: 'FT',
    name: 'Flexible Container bag',
  },
  {
    code: 'FWR',
    name: 'Forward Reel',
  },
  {
    code: 'GOH',
    name: 'Garments on Hangers',
  },
  {
    code: 'HED',
    name: 'Head of Beef',
  },
  {
    code: 'HGH',
    name: 'HOGS HEAD',
  },
  {
    code: 'HPR',
    name: 'HAMPER',
  },
  {
    code: 'HPT',
    name: 'Hopper Truck',
  },
  {
    code: 'HR',
    name: 'Hamper',
  },
  {
    code: 'HRB',
    name: 'On Hanger or Rack in Boxes',
  },
  {
    code: 'HRK',
    name: 'Half-Standard Rack',
  },
  {
    code: 'HTB',
    name: 'Half-Standard Tote Bin',
  },
  {
    code: 'IN',
    name: 'Ingot',
  },
  {
    code: 'JAR',
    name: 'Jar',
  },
  {
    code: 'JT',
    name: 'Jutebag',
  },
  {
    code: 'JUG',
    name: 'Jug',
  },
  {
    code: 'KEG',
    name: 'Keg',
  },
  {
    code: 'KIT',
    name: 'Kit',
  },
  {
    code: 'KRK',
    name: 'Knockdown Rack',
  },
  {
    code: 'KTB',
    name: 'Knockdown Tote Bin',
  },
  {
    code: 'LBK',
    name: 'LIQUID BULK',
  },
  {
    code: 'LID',
    name: 'LIP/TOP',
  },
  {
    code: 'LIF',
    name: 'Lifts',
  },
  {
    code: 'LNR',
    name: 'LINERS',
  },
  {
    code: 'LOG',
    name: 'LOG',
  },
  {
    code: 'LSE',
    name: 'Loose',
  },
  {
    code: 'LT',
    name: 'Lot',
  },
  {
    code: 'LUG',
    name: 'Lugs',
  },
  {
    code: 'LVN',
    name: 'LIFT VAN',
  },
  {
    code: 'LZ',
    name: 'Logs, in bundle/bunch/truss',
  },
  {
    code: 'MIX',
    name: 'MIXED CONTAINER TYPES',
  },
  {
    code: 'MLV',
    name: 'MILVAN - Military Van',
  },
  {
    code: 'MRP',
    name: 'Multi-Roll Pack',
  },
  {
    code: 'MSV',
    name: 'MILITARY SEALIFT COMMAND VAN',
  },
  {
    code: 'MT',
    name: 'Mat',
  },
  {
    code: 'MXD',
    name: 'Mixed Type Pack',
  },
  {
    code: 'NOL',
    name: 'Noil',
  },
  {
    code: 'NS',
    name: 'Nest',
  },
  {
    code: 'NT',
    name: 'Net',
  },
  {
    code: 'OK',
    name: 'Block',
  },
  {
    code: 'OVW',
    name: 'Overwrap',
  },
  {
    code: 'PA',
    name: 'Packet',
  },
  {
    code: 'PAF',
    name: 'PALLET-4 WAY',
  },
  {
    code: 'PAL',
    name: 'Pail',
  },
  {
    code: 'PAT',
    name: 'PALLET-2 WAY',
  },
  {
    code: 'PB',
    name: 'Pallet & Package',
  },
  {
    code: 'PC',
    name: 'Parcel',
  },
  {
    code: 'PCE',
    name: 'PIECE',
  },
  {
    code: 'PCK',
    name: 'Packed - not otherwise specified',
  },
  {
    code: 'PCS',
    name: 'Piece',
  },
  {
    code: 'PF',
    name: 'Pen',
  },
  {
    code: 'PG',
    name: 'Plate',
  },
  {
    code: 'PH',
    name: 'Pitcher',
  },
  {
    code: 'PI',
    name: 'Pipe',
  },
  {
    code: 'PIR',
    name: 'Pims',
  },
  {
    code: 'PKG',
    name: 'Package',
  },
  {
    code: 'PLC',
    name: 'PRIMARY LIFT CONTAINER',
  },
  {
    code: 'PLF',
    name: 'Platform',
  },
  {
    code: 'PLN',
    name: 'Pipeline',
  },
  {
    code: 'PLT',
    name: 'Pallet',
  },
  {
    code: 'PO',
    name: 'Pouch',
  },
  {
    code: 'POV',
    name: 'Private Vehicle',
  },
  {
    code: 'PRK',
    name: 'Pipe Rack',
  },
  {
    code: 'PRT',
    name: 'PARTITIONING',
  },
  {
    code: 'PT',
    name: 'Pot',
  },
  {
    code: 'PY',
    name: 'Plates, in bundle/bunch/truss',
  },
  {
    code: 'PZ',
    name: 'Planks, in bundle/bunch/truss',
  },
  {
    code: 'QTR',
    name: 'Quarters of Beef',
  },
  {
    code: 'RAL',
    name: 'Rail (Semiconductor)',
  },
  {
    code: 'RCK',
    name: 'RACK',
  },
  {
    code: 'REL',
    name: 'Reel',
  },
  {
    code: 'RFT',
    name: 'REINFORCEMENT',
  },
  {
    code: 'RG',
    name: 'Ring',
  },
  {
    code: 'ROL',
    name: 'Roll',
  },
  {
    code: 'RVR',
    name: 'Reverse Reel',
  },
  {
    code: 'SAK',
    name: 'Sack',
  },
  {
    code: 'SB',
    name: 'Slab',
  },
  {
    code: 'SBC',
    name: 'Liner Bag Dry',
  },
  {
    code: 'SD',
    name: 'Spindle',
  },
  {
    code: 'SET',
    name: 'Set',
  },
  {
    code: 'SH',
    name: 'Sachet',
  },
  {
    code: 'SHK',
    name: 'Shook',
  },
  {
    code: 'SHT',
    name: 'Sheet',
  },
  {
    code: 'SHW',
    name: 'Shrinkwrapped',
  },
  {
    code: 'SID',
    name: 'Side of Beef',
  },
  {
    code: 'SK',
    name: 'Case, skeleton',
  },
  {
    code: 'SKD',
    name: 'Skid',
  },
  {
    code: 'SKE',
    name: 'Skid elevating or lift truck',
  },
  {
    code: 'SL',
    name: 'Slip Sheet',
  },
  {
    code: 'SLP',
    name: 'SLIP SHEET',
  },
  {
    code: 'SLV',
    name: 'SLEEVE',
  },
  {
    code: 'SPI',
    name: 'Spin Cylinders',
  },
  {
    code: 'SPL',
    name: 'SPOOL',
  },
  {
    code: 'SPR',
    name: 'SEPARATOR \\ DIVIDER',
  },
  {
    code: 'SS',
    name: 'Steel Case',
  },
  {
    code: 'SU',
    name: 'Suitcase',
  },
  {
    code: 'SV',
    name: 'Steel Envelop',
  },
  {
    code: 'SVN',
    name: 'SEAVAN - Sea Van',
  },
  {
    code: 'SZ',
    name: 'Sheet,in bundle/bunch/truss',
  },
  {
    code: 'TBE',
    name: 'TUBE',
  },
  {
    code: 'TBN',
    name: 'Tote Bin',
  },
  {
    code: 'TIN',
    name: 'Tin',
  },
  {
    code: 'TKR',
    name: 'Tank Car',
  },
  {
    code: 'TKT',
    name: 'Tank Truck',
  },
  {
    code: 'TLD',
    name: 'TRAILER \\ CONTAINER LOAD (RAIL)',
  },
  {
    code: 'TNK',
    name: 'Tank',
  },
  {
    code: 'TO',
    name: 'Tun',
  },
  {
    code: 'TRC',
    name: 'Tierce',
  },
  {
    code: 'TRI',
    name: 'Triwall',
  },
  {
    code: 'TRK',
    name: 'TRUNK AND CHEST',
  },
  {
    code: 'TRU',
    name: 'Truck',
  },
  {
    code: 'TRY',
    name: 'Tray',
  },
  {
    code: 'TS',
    name: 'Truss',
  },
  {
    code: 'TSS',
    name: 'Trunk, Salesmen Sample',
  },
  {
    code: 'TTC',
    name: 'Tote Can',
  },
  {
    code: 'TUB',
    name: 'Tub',
  },
  {
    code: 'UNP',
    name: 'Unpacked',
  },
  {
    code: 'UNT',
    name: 'Unit',
  },
  {
    code: 'VA',
    name: 'Vat',
  },
  {
    code: 'VEH',
    name: 'Vehicles',
  },
  {
    code: 'VG',
    name: 'Bulk, gas (at 1031 mbar and 15øC)',
  },
  {
    code: 'VI',
    name: 'Vial',
  },
  {
    code: 'VL',
    name: 'Liquid Bulk',
  },
  {
    code: 'VO',
    name: 'Bulk, solid, large particles',
  },
  {
    code: 'VPK',
    name: 'Van Pack',
  },
  {
    code: 'VQ',
    name: 'Bulk, liqfd gas (abnormal tmp/pres)',
  },
  {
    code: 'VR',
    name: 'Bulk, solid, granular particles',
  },
  {
    code: 'VY',
    name: 'Bulk, solid, fine particles',
  },
  {
    code: 'WDC',
    name: 'Wooden Case',
  },
  {
    code: 'WHE',
    name: 'On Own Wheels',
  },
  {
    code: 'WLC',
    name: 'Wheeled Carrier',
  },
  {
    code: 'WRP',
    name: 'Wrapped',
  },
];
export const MBL_TYPE = {
  CL: 'CO-LOAD',
  CS: 'CONSOL',
  DR: 'DIRECT',
  DT: 'DIRECT TRIANGLE',
  FW: 'FORWARDING',
  NR: 'NORMAL',
  TP: 'THIRD PARTY',
  TR: 'TRIANGLE',
  OT: 'OTHER',
};

export const SHIP_TYPE = {
  ocean: 'Ocean',
  air: 'Air',
  truck: 'Truck',
  warehouse: 'Warehouse',
  courier: 'Courier',
  others: 'Others',
  NORMAL: 'Normal',
  S_W: 'S_W',
  T_S: 'T_S',
};

export const NOTIFICATION_ENTITY_TYPES = ['approval', 'statement', 'inbox_item'];
