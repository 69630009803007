export default {
  'accounting.list_title.charge_item_codes': 'Charge Item Codes',
  'accounting.charge_item_code.field.name': 'Name',
  'accounting.charge_item_code.field.local_name': 'Name(Local)',
  'accounting.charge_item_code.field.code': 'Code',
  'accounting.charge_item_code.field.default_currency': 'Default Currency',
  'accounting.charge_item_code.field.description': 'Description',
  'accounting.charge_item_code.field.qty': 'Qty',
  'accounting.charge_item_code.field.price': 'Price',
  'accounting.charge_item_code.field.amount': 'Amount',
  'accounting.charge_item_code.field.type': 'Type',
  'accounting.charge_item_code.type.income': 'Income',
  'accounting.charge_item_code.type.expense': 'Expense',
  'accounting.charge_item_code.type.income_or_expense': 'Income/Expense',
  'accounting.charge_item_code.update.success': 'Successfully Update Charge Item Code',
  'accounting.charge_item_code.delete.success': 'Successfully Delete Charge Item Code',
  'accounting.charge_item_code.create.success': 'Successfully Create Charge Item Code',
  'accounting.charge_item_code.delete.confirm.title':
    'Are you sure you want to delete this charge item code?',
  'accounting.charge_item_code.add.title': 'Add Charge Item Code',
  'accounting.charge_item_template.ready_to_pay': 'Ready to Pay',
  'accounting.charge_item_template.not_ready': 'Not Ready',
  'accounting.charge_item_template.select_template': 'Select Template',
  'accounting.charge_item_template.select_template.placeholder': 'Select Template',
  'accounting.charge_item_template.select_template.required': 'Please select a template',
  'accounting.charge_item_template.field.target': 'Target',
  'accounting.charge_item_template.title': 'Charge Item Templates',
  'accounting.charge_item_template.field.bill': 'Bill#',
  'accounting.charge_item_template.field.name': 'Name',
  'accounting.charge_item_template.field.created_by': 'Created By',
  'accounting.charge_item_template.field.created_at': 'Created At',
  'accounting.charge_item_template.field.updated_at': 'Updated At',
  'accounting.charge_item_template.field.name.required': 'Please input name',
  'accounting.charge_item_template.field.receivable_items': 'Receivable Items',
  'accounting.charge_item_template.field.payable_items': 'Payable Items',
  'accounting.charge_item_template.field.dc_note_items': 'D/C Note Items',
  'accounting.charge_item_template.field.is_shared': 'Shared',
  'accounting.charge_item_template.onlyOneLineEditorAlertMessage':
    'Only one item can be edited at a time',
  'accounting.charge_item_template.onlyAddOneLineAlertMessage':
    'Only one item can be added at a time',
  'accounting.charge_item_template.add.button': 'Add Item',
  'accounting.charge_item_template.empty': 'No Items',
  'accounting.charge_item_template.add': 'Add Charge Item Template',
  'accounting.charge_item_template.load_from_template': 'Load from template',

  'accounting.charge_item_template.create.success': 'Successfully Create new charge item template',
  'accounting.charge_item_template.update.success': 'Successfully Update Charge Item Template',
  'accounting.charge_item_template.delete.success': 'Successfully Delete Charge Item Template',
  'accounting.charge_item_template.duplicate.success':
    'Successfully Duplicate Charge Item Template',
  'accounting.charge_item_template.delete.confirm.title':
    'Are you sure you want to delete this charge item template?',
  'accounting.charge_item_template.duplicate.confirm.title':
    'Are you sure you want to duplicate this charge item template?',

  'accounting.tooltip.create_statement_for_this_contact': 'Create Statement for this contact',
  'accounting.arap.field.charge_items.required': 'Please add charge items',
  'accounting.arap.field.charge_items.missing_code':
    'Please make sure all charge items have item code',
  'accounting.statement.document_name': 'ACCOUNTING STATEMENT',
  'accounting.statement.field.report_date': 'Report Date',
  'accounting.statement.field.statement_date': 'Statement Date',
  'accounting.statement.field.statement_period': 'Statement Period',
  'accounting.statement.field.contact': 'Contact',
  'accounting.statement.field.est': 'EST',
  'accounting.statement.field.account_name': 'Account Name',
  'accounting.statement.field.code': 'Inv/Bill/Note #',
  'accounting.statement.field.accounting_mode': 'View By',
  'accounting.statement.field.accounting_mode.invoice': 'Invoice',
  'accounting.statement.field.accounting_mode.shipment': 'Shipment',
  'accounting.statement.field.accounting_mode.charge_item': 'Charge Item',
  'accounting.statement.field.statement_code': 'Statement #',
  'accounting.statement.field.payment_plan_code': 'Payment Plan #',
  'accounting.statement.field.item': 'Items',
  'accounting.statement.field.ci': 'CI#',
  'accounting.statement.field.type.receivable': 'Receivable',
  'accounting.statement.field.type.payable': 'Payable',
  'accounting.statement.field.receivable_sum': 'Receivable',
  'accounting.statement.field.payable_sum': 'Payable',
  'accounting.statement.field.incl_reco': 'Reco',
  'accounting.statement.field.incl_ap': 'A/P',
  'accounting.statement.field.incl_ar': 'A/R',
  'accounting.statement.field.incl_dc': 'D/C',
  'accounting.statement.field.incl_has_statement': 'Has Statement',
  'accounting.statement.field.incl_paid': 'Paid',
  'accounting.statement.field.date_range': 'Date Range',
  'accounting.statement.field.total': 'Total',
  'accounting.statement.field.remaining_balance': 'Remaining Balance',
  'accounting.statement.field.created_by': 'Created By',
  'accounting.statement.create.success': 'Statement created successfully',
  'accounting.statement.toolbar.my_statements': 'My Statements',
  'accounting.statement.toolbar.outstanding_statements': 'Outstanding Statements',
  'accounting.statement.toolbar.all_statements': 'All Statements',
  'accounting.statement.crate': 'Create New Statement',
  'accounting.statement.empty': 'No Statements',
  'accounting.statement.please_select_contact': 'Please Select Contact',
  'accounting.statement.account_statement_report': 'Account Statement Report',
  'accounting.statement.applied.success': 'Successfully applied credit',
  'accounting.statement.added.success': 'Successfully added payment',
  'accounting.statement.delete.confirm': 'Are you sure you want to delete this statement?',
  'accounting.statement.added_item.success': 'Successfully added items',
  'accounting.statement.removed_item.success': 'Successfully removed items',
  'accounting.statement.deleted.success': 'Successfully deleted Statement',
  'accounting.statement.mark_as_paid.success': 'Successfully marked entries as paid',
  'accounting.statement.mark_as_paid': 'Mark as Paid',
  'accounting.statement.mark_as_paid.confirm':
    'Are you sure you want to mark these entries as paid?',
  'accounting.statement.amount_total': 'Amount Total',
  'accounting.statement.debit_total': 'Debit Total',
  'accounting.statement.credit_total': 'Credit Total',
  'accounting.statement.balance_total': 'Balance Total',
  'accounting.statement.send.success': 'Statement sent successfully',
  'accounting.statement.generate_email_pdf': 'Generate/Email PDF',
  'accounting.statement.generate_debit_note_email_pdf': 'Generate Debit Note/Email PDF',
  'accounting.statement.add_incoming_payment': 'Add Incoming Payment',
  'accounting.statement.add_outgoing_payment': 'Add Outgoing Payment',
  'accounting.statement.add_open_revenue_item': 'Add Open Revenue/Cost Item(s)',
  'accounting.statement.email_statement': 'Email Statement',
  'accounting.statement.undo_reconcile': 'Undo Statement Submit',
  'accounting.statement.undo_reconcile.confirm': 'Are you sure you want to undo statement submit?',
  'accounting.statement.download.title': 'Download statement in excel file format',
  'accounting.invoice.create_arrival_notice_confirm.title':
    'Are you sure to create an Arrival Notice?',
  'accounting.invoice.add_invoice_existing.confirm.title':
    'Are you sure to add the invoice to an existing invoice?',
  'accounting.invoice.create_pdf.confirm.title': 'Are you sure to create the PDF for this invoice',
  'accounting.invoice.create_pdf.success': 'PDF created successfully',
  'accounting.invoice.title.new_invoice': 'New Invoice',
  'accounting.invoice.partially_received': 'Partially Received',
  'accounting.invoice.p_r': 'P/R',
  'accounting.invoice.view_invoice': 'View Invoice',
  'accounting.invoice.edit_invoice': 'Edit Invoice',
  'accounting.invoice.field.code': 'Invoice No.',
  'accounting.invoice.field.status': 'Status',
  'accounting.invoice.field.created_by': 'Created By',
  'accounting.invoice.field.code.required': 'Please input invoice no.',
  'accounting.invoice.field.invoice': 'Invoice#',
  'accounting.invoice.field.code.message': 'Please input invoice number',
  'accounting.invoice.field.date': 'Invoice Date',
  'accounting.invoice.field.date.message': 'Please select invoice date',
  'accounting.invoice.field.due_date': 'Due Date',
  'accounting.invoice.field.due_date.message': 'Please select due date',
  'accounting.invoice.field.contact': 'Bill To',
  'accounting.invoice.field.received': 'Received',
  'accounting.invoice.field.contact.message': 'Please select bill to',
  'accounting.invoice.field.memo': 'Memo',
  'accounting.invoice.field.charge_items.creatorButton': 'Add Charge Item',
  'accounting.invoice.field.charge_items.empty': 'No Charge Items',
  'accounting.invoice.field.paid_amount': 'Paid Amount',
  'accounting.invoice.field.pending_amount': 'Pending Amount',
  'accounting.invoice.field.currency_rate_source': 'Currency Rate Source',
  'accounting.invoice.field.currency_rate_source.setting': 'Setting',
  'accounting.invoice.field.currency_rate_source.override': 'Manual',
  'accounting.invoice.field.receipt_no': 'Receipt No.',
  'accounting.invoice.field.ref_no': 'Ref./PO No.',
  'accounting.invoice.field.taxable_amount': 'Taxable Amount',
  'accounting.invoice.button.set_currency_rate': 'Set Currency Rate',
  'accounting.invoice.update.success': 'Invoice updated successfully',
  'accounting.invoice.create.success': 'Invoice created successfully',
  'accounting.invoice.publish.success': 'Invoice published successfully',
  'accounting.invoice.delete.success': 'Invoice deleted successfully',
  'accounting.invoice.delete.confirm': 'Are you sure to delete this invoice?',
  'accounting.invoice.delete.already_link.confirm':
    'This invoice already link shipment ({ code }) , Are you sure to delete this bill?',
  'accounting.invoice.void.success': 'Invoice voided successfully',
  'accounting.invoice.link.success': 'Invoice linked successfully',
  'accounting.invoice.unlink.success': 'Invoice unlinked successfully',
  'accounting.invoice.pdf.generating': 'Generating PDF...',
  'accounting.invoice.title.link_shipment': 'Link Shipment',
  'accounting.invoice.title.unlink_shipment': 'Unlink Shipment',
  'accounting.invoice.onlyOneLineEditorAlertMessage': 'Only one item can be edited at a time',
  'accounting.invoice.onlyAddOneLineAlertMessage': 'Only one item can be added at a time',
  'accounting.invoice.title.shipment_information': 'Shipment Information',
  'accounting.invoice.empty': 'No Invoices',
  'accounting.invoice.title': 'Invoices',
  'accounting.invoice.create_pdf': 'Create PDF',
  'accounting.invoice.recreate_pdf': 'Recreate PDF',
  'accounting.invoice.create.title': 'Create Invoice',
  'accounting.invoice.create.notice':
    'Do not create invoice if you need to include this in the arrival notice.',
  'accounting.invoice.copy': 'Copy to Invoice Code',
  'accounting.invoice.tools.print_invoice': 'Print Invoice',
  'accounting.invoice.tools.profit_report': 'Profit Report',

  'accounting.transaction.add_payment': 'Add Payment',
  'accounting.transaction.field.total_remaining_amount': 'Total Remaining Amount',
  'accounting.transaction.field.remaining_balance': 'Remaining Balance',
  'accounting.transaction.field.balance_with_ready_to_pay': 'Balance with Ready To Pay',
  'accounting.transaction.field.use_credits': 'Use Credits',
  'accounting.transaction.field.available_credits': 'Available Credits',
  'accounting.transaction.field.type': 'Payment Type',
  'accounting.transaction.field.account': 'Account',
  'accounting.transaction.field.category': 'Category',
  'accounting.transaction.field.pay_date': 'Pay Date',
  'accounting.transaction.field.submitted_by': 'Submitted By',
  'accounting.transaction.field.confirmed_by': 'Confirmed By',
  'accounting.transaction.field.submitted_at': 'Deposit Date',
  'accounting.transaction.field.voided_at': 'Voided At',
  'accounting.transaction.field.type.required': 'Please select payment type',
  'accounting.transaction.field.transaction_date': 'Transaction Date',
  'accounting.transaction.field.transaction_date.required': 'Please input transaction/check date',
  'accounting.transaction.field.notes': 'Notes',
  'accounting.transaction.field.photo': 'Photo',
  'accounting.transaction.field.vendor': 'Vendor',
  'accounting.transaction.field.total': 'Total',
  'accounting.transaction.field.balance': 'Balance',
  'accounting.transaction.field.pending_amount': 'Pending Amount',
  'accounting.transaction.field.shipment': 'Shipment',
  'accounting.transaction.field.codes': 'Invoice / Bill / D/C Note #',
  'accounting.transaction.field.bill_to': 'Bill To',
  'accounting.transaction.field.contact': 'Contact',
  'accounting.transaction.field.transaction_code': 'Transaction No.',
  'accounting.transaction.field.account_currency': 'Account Currency',
  'accounting.transaction.field.invoice_number': 'No.',
  'accounting.transaction.incl_reco': 'Incl. Reco.',
  'accounting.transaction.create_prepayment': 'Create Prepayment',
  'accounting.transaction.pending_deposits': 'Pending Deposits',
  'accounting.transaction.all_deposit_transactions': 'All Deposit Transactions',
  'accounting.transaction.all_payment_transactions': 'All Payment Transactions',
  'accounting.transaction.bank_reconciliation': 'Bank Reconciliation',
  'accounting.transaction.confirm.success': 'Successfully confirmed {length} transaction',
  'accounting.transaction.reconcile.success': 'Successfully Reconcile {length} transaction',
  'accounting.transaction.total_amount': 'Total amount',
  'accounting.transaction.total_payment_amount': 'Total payment amount',
  'accounting.transaction.original_amount': 'Original Amount',
  'accounting.transaction.main_currency': 'Main Currency',
  'accounting.transaction.transaction_currency': 'Transaction Currency',
  'accounting.transaction.exchange_gain_loss': 'Exchange Gain/Loss',
  'accounting.transaction.total_credit': 'Total Credit',
  'accounting.transaction.total_debit': 'Total Debit',
  'accounting.transaction.cleared_balance': 'Cleared Balance',
  'accounting.transaction.bank_reconcilie': 'Bank Reconcilie',
  'accounting.transaction.bank_unreconcilie': 'Undo Bank Reconciliations',
  'accounting.transaction.confirm': 'Are you sure to confirm these deposits?',
  'accounting.transaction.reconcile.confirm': 'Are you sure to reconcile these transactions?',
  'accounting.transaction.emailed.success': 'Successfully emailed documents.',
  'accounting.transaction.create.success': 'Transaction {code} has been created successfully',
  'accounting.transaction.updated.success': 'Transaction {code} has been updated successfully',
  'accounting.transaction.voided.success': 'Transaction {code} has been voided',
  'accounting.transaction.unreconciled.success': 'Transaction {code} has been unreconciled',
  'accounting.transaction.deleted.success': 'Transaction has been deleted',
  'accounting.transaction.unreconciled.confirm':
    'Are your sure you want to unreconcile this transaction?',
  'accounting.transaction.confirm.confirm':
    '          Are your sure you want to confirm this transaction?\n',
  'accounting.transaction.void.confirm': 'Are your sure you want to void this transaction?',
  'accounting.transaction.confirmed.success': 'Transaction {code} has been confirmed',
  'accounting.transaction.undone': 'This action cannot be undone.',
  'accounting.transaction.confirm_deposit': 'Confirm Deposit',
  'accounting.transaction.income': 'Income',
  'accounting.transaction.payment': 'Payment',
  'accounting.transaction.payment_amount': 'Payment',
  'accounting.transaction.email_title': 'Email Transaction',
  'accounting.transaction.email_transaction_record': 'Email Transaction Record',
  'accounting.transaction.transaction_details': 'Transaction Details',
  'accounting.transaction.tab.info': 'Info',
  'accounting.transaction.tab.document': 'Document',
  'accounting.transaction.tab.status': 'Status',
  'accounting.transaction.currency_override_reload.confirm':
    'Do you want to update the currency rates?',
  'accounting.transaction.delete.confirm': 'Are you sure you want to delete this transaction?',
  'accounting.transaction.invoice_list': 'Invoice List',
  'accounting.transaction.add_gl_item': 'Add G/L Item',
  'accounting.transaction.gl_code.required': 'Please select G/L Code',
  'accounting.transaction.select_transaction_currency': 'Select Transaction Currency',
  'accounting.transaction.select_charge_item_currency': 'Select Charge Item Currency',

  'accounting.bill.field.contact': 'Pay To',
  'accounting.bill.field.contact.message': 'Please select pay to',
  'accounting.dc_note.credit': 'Credit Note',
  'accounting.dc_note.debit': 'Debit Note',
  'accounting.dc_note.credit.list_title.all': 'All Credit Notes',
  'accounting.dc_note.debit.list_title.all': 'All Debit Notes',
  'accounting.dc_note.credit.create': 'Create Credit Note',
  'accounting.dc_note.debit.create': 'Create Debit Note',
  'accounting.dc_note.credit.create.success': 'Credit Note created successfully',
  'accounting.dc_note.debit.create.success': 'Debit Note created successfully',
  'accounting.dc_note.credit.update.success': 'Credit Note updated successfully',
  'accounting.dc_note.debit.update.success': 'Debit Note updated successfully',
  'accounting.dc_note.credit.delete.success': 'Credit Note deleted successfully',
  'accounting.dc_note.debitdebit.delete.success': 'Debit Note deleted successfully',
  'accounting.dc_note.credit.delete.confirm': 'Are you sure to delete this Credit Note?',
  'accounting.dc_note.debit.delete.confirm': 'Are you sure to delete this Debit Note?',
  'accounting.dc_note.debit.delete.already_link.confirm':
    'This Debit Note already link shipment ({ code }) , Are you sure to delete this Debit Note?',
  'accounting.dc_note.credit.delete.already_link.confirm':
    'This Credit Note already link shipment ({ code }) , Are you sure to delete this Credit Note?',

  'accounting.dc_note.credit.void.success': 'Credit Note voided successfully',
  'accounting.dc_note.debit.void.success': 'Debit Note voided successfully',
  'accounting.dc_note.credit.link.success': 'Credit Note linked successfully',
  'accounting.dc_note.debit.link.success': 'Debit Note linked successfully',
  'accounting.dc_note.credit.unlink.success': 'Credit Note unlinked successfully',
  'accounting.dc_note.debit.unlink.success': 'Debit Note unlinked successfully',
  'accounting.dc_note.credit.back_to_draft.success':
    'Turn Credit Note back to draft status successfully',
  'accounting.dc_note.debit.back_to_draft.success':
    'Turn Debit Note back to draft status successfully',
  'accounting.dc_note.field.contact': 'Contact',
  'accounting.dc_note.field.contact.message': 'Please select contact',
  'accounting.dc_note.credit.field.code': 'Credit Note No.',
  'accounting.dc_note.credit.field.code.message': 'Please input credit note number',
  'accounting.dc_note.debit.field.code': 'Debit Note No.',
  'accounting.dc_note.debit.field.code.message': 'Please input debit note number',
  'accounting.dc_note.field.date': 'Date',
  'accounting.dc_note.field.date.message': 'Please select date',
  'accounting.dc_note.field.due_date': 'Due Date',
  'accounting.dc_note.field.memo': 'Memo',
  'accounting.dc_note.field.balance': 'Balance Amount',
  'accounting.dc_note.field.paid': 'Applied Amount',
  'accounting.dc_note.field.pending': 'Pending Amount',
  'accounting.dc_note.field.amount': 'Total Amount',
  'accounting.dc_note.field.status': 'Status',
  'accounting.dc_note.back_to_draft': 'Back to Draft',
  'accounting.dc_note.link': 'Link to Shipment',
  'accounting.dc_note.unlink': 'Unlink to Shipment',
  'accounting.dc_note.view_pdf': 'View PDF',
  'accounting.dc_note.deletePopconfirmMessage': 'Delete this item?',
  'accounting.dc_note.onlyOneLineEditorAlertMessage': 'Only one item can be edited at a time',
  'accounting.dc_note.onlyAddOneLineAlertMessage': 'Only one item can be added at a time',
  'accounting.dc_note.title.shipment_information': 'Shipment Information',
  'accounting.transaction.field.payer': 'Payer',
  'accounting.transaction.field.pay_to': 'Pay To',
  'accounting.transaction.field.account.message': 'Please select account',
  'accounting.transaction.field.code': 'Transaction No.',
  'accounting.transaction.field.code.required': 'Please input transaction/check no.',
  'accounting.transaction.field.amount': 'Amount',
  'accounting.transaction.field.amount.required': 'Please input amount',
  'accounting.transaction.field.txn_code': 'Txn No.',
  'accounting.transaction.field.txn_code.tag.voided': 'Voided',
  'accounting.transaction.field.txn_code.tag.pending': 'Pending',
  'accounting.transaction.field.txn_code.tag.reconciled': 'Reconciled',
  'accounting.transaction.messages.prepayment.create.success': 'Prepayment created successfully',
  'accounting.transaction.type.check': 'Check',
  'accounting.transaction.type.cash': 'Cash',
  'accounting.transaction.type.ach': 'ACH',
  'accounting.transaction.type.credit_card': 'Credit Card',
  'accounting.transaction.type.debit_card': 'Debit Card',
  'accounting.transaction.type.emodal': 'eModal',
  'accounting.transaction.type.zelle': 'Zelle',
  'accounting.transaction.type.wire': 'Wire',
  'accounting.transaction.type.pay_cargo': 'Pay Cargo',
  'accounting.transaction.type.bill_pay': 'Bill Pay',
  'accounting.transaction.type.cargo_sprint': 'Cargo Sprint',
  'accounting.transaction.type.offset': 'Offset',
  'accounting.transaction.type.e_check': 'E-Check',
  'accounting.transaction.type.epic': 'Epic',
  'accounting.transaction.type.financial_reconcile': 'Financial Reconcile',
  'accounting.transaction.type.total_amount': 'Total Amount',
  'accounting.transaction.lock.success': 'Transaction(s) locked successfully',
  'accounting.transaction.unlock.success': 'Transaction(s) unlocked successfully',
  'accounting.transaction.incoming': 'Incoming',
  'accounting.transaction.outgoing': 'Outgoing',
  'accounting.balance.total.title': 'Total of {name}',
  'accounting.balance.balance_sheet.title': 'Balance Sheet',
  'accounting.balance_sheet.field.include_till': 'Include Till',
  'accounting.balance.assets.title': 'Assets',
  'accounting.balance.total_liabilities.title': 'Total Liabilities',
  'accounting.balance.equity.title': 'Equity',
  'accounting.balance.total_equity.title': 'Total Equity',
  'accounting.account.list_title.all': 'All Accounts',
  'accounting.account.field.name': 'Name',
  'accounting.account.field.bank_name': 'Bank',
  'accounting.account.field.number': 'Account Number',
  'accounting.account.field.opening_balance': 'Opening Balance',
  'accounting.account.field.balance': 'Balance',
  'accounting.account.field.is_main': 'Main Account',
  'accounting.account.edit.title': 'Edit Account',
  'accounting.account.create.title': 'Create Account',
  'accounting.account.create': 'Create New Account',
  'accounting.account.create.success': 'Account created successfully',
  'accounting.account.update.success': 'Account updated successfully',
  'accounting.account.delete.success': 'Account deleted successfully',
  'accounting.account.delete.confirm.title': 'Are you sure to delete this account?',
  'accounting.account.delete.confirm.content': 'This action cannot be undone',
  'accounting.account.account_balance': 'Account Balance',
  'accounting.general_journal.journal_entry': 'Journal Entry',
  'accounting.general_journal.list.title': 'General Journal',
  'accounting.general_journal.create': 'Create Journal Entry',
  'accounting.general_journal.field.post_date': 'Post Date',
  'accounting.general_journal.field.post_date.message': 'Please select post date',
  'accounting.general_journal.field.date_seq': 'Seq',
  'accounting.general_journal.field.type': 'Type',
  'accounting.general_journal.field.remark': 'Remark',
  'accounting.general_journal.field.debit': 'Debit',
  'accounting.general_journal.field.credit': 'Credit',
  'accounting.general_journal.field.issue_by': 'Issued By',
  'accounting.general_journal.field.block_by': 'Blocked By',
  'accounting.general_journal.field.general_ledgers.add': 'Add Entry',
  'accounting.general_journal.field.general_ledgers.add_balance': 'Add a new balance entry',
  'accounting.general_journal.field.gl_code': 'G/L Code',
  'accounting.general_journal.field.gl_code.message': 'Please select G/L Code',
  'accounting.general_journal.field.gl_code.name': 'G/L Desc.',
  'accounting.general_journal.field.type.cash': 'Bank',
  'accounting.general_journal.field.type.general': 'Company',
  'accounting.general_journal.field.contact': 'Contact',
  'accounting.general_journal.field.contact.message': 'Please select contact',
  'accounting.general_journal.field.description': 'Description',
  'accounting.general_journal.field.ref_no': 'Ref. No.',
  'accounting.general_journal.field.source': 'Source',
  'accounting.general_journal.field.source.journal': 'Journal',
  'accounting.general_journal.field.source.ar': 'A/R',
  'accounting.general_journal.field.source.ap': 'A/P',
  'accounting.general_journal.field.source.check': 'Check',
  'accounting.general_journal.field.source.deposit': 'Deposit',
  'accounting.general_journal.field.source.payment': 'Payment',
  'accounting.general_journal.field.source.prepayment': 'Prepayment',
  'accounting.general_journal.field.source.dc_note': 'D/C Note',
  'accounting.general_journal.field.source.voided_transaction': 'Voided Transaction',
  'accounting.general_journal.field.source.voided_invoice': 'Voided Invoice',
  'accounting.general_journal.field.source.voided_bill': 'Voided Bill',
  'accounting.general_journal.field.source.voided_dc_note': 'Voided D/C Note',
  'accounting.general_journal.field.source.shipment': 'Shipment',
  'accounting.general_journal.create.success': 'Journal Entry created successfully',
  'accounting.general_journal.update.success': 'Journal Entry updated successfully',
  'accounting.general_journal.delete.success': 'Journal Entry deleted successfully',
  'accounting.general_journal.delete.confirm.title': 'Are you sure to delete this journal entry?',
  'accounting.general_journal.delete.confirm.content': 'This action cannot be undone',
  'accounting.general_journal.lock.success': 'Journal Entry locked successfully',
  'accounting.general_journal.unlock.success': 'Journal Entry unlocked successfully',
  'accounting.general_ledger.transaction_currency': 'Transactionn Currency',
  'accounting.general_ledger.report.excel_download': 'Export General Ledgers',
  'accounting.general_ledger.message.debit_credit_totals_not_match':
    'Debit and Credit totals do not match',
  'accounting.locks.journals': 'General Journals',
  'accounting.locks.shipments': 'Shipments',
  'accounting.locks.arap': 'Invoices/Bills/DC Notes',
  'accounting.locks.transactions': 'Transactions',
  'accounting.locks.all': 'Lock/Unlock All',
  'accounting.locks.all.field.before_date': 'Before Date',
  'accounting.locks.all.field.before_date.message':
    'Please select the date before (and include) which all entities will be locked',
  'accounting.locks.all.field.after_date': 'After Date',
  'accounting.locks.all.field.after_date.message':
    'Please select the date after (and include) which all entities will be unlocked',
  'accounting.locks.all.before_or_after_date': 'Before/After Date',
  'accounting.locks.all.lock.confirm.content':
    'Are you sure to lock all entities posted before {date}?',
  'accounting.locks.all.unlock.confirm.content':
    'Are you sure to unlock all entities posted after {date}?',
  'accounting.locks.all.changelog': 'Lock/Unlock All Records',
  'accounting.locks.unblockChanges': 'Unlock Records',
  'accounting.locks.changelog.type.unblock_all': 'Unlock',
  'accounting.locks.changelog.type.block_all': 'Lock',
  'accounting.locks.changelog.type.unblock_all_after': 'Unlock after {date}',
  'accounting.locks.changelog.type.block_all_before': 'Lock before {date}',
  'accounting.arap.publish.confirm': 'Are you sure to publish these items?',
  'accounting.invoice': 'Receivables',
  'accounting.other_invoice': 'Other Receivables',
  'accounting.bill': 'Payables',
  'accounting.other_bill': 'Other Payables',
  'accounting.dc-note': 'Debit/Credit Notes',
  'accounting.dc_note': 'Debit/Credit Notes',
  'accounting.arap.field.amount': 'Amount',
  'accounting.invoice.notifications.lock.title': 'Invoice Locked',
  'accounting.invoice.notifications.lock.description': 'This invoice is locked by {name}',
  'accounting.bill.notifications.lock.title': 'Bill Locked',

  'accounting.bill.notifications.lock.description': 'This bill is locked by {name}',
  'accounting.bill.filed.invoice': 'Invoice #',
  'accounting.bill.name_document_no': 'Name/Document No.',
  'accounting.bill.field.type': 'Type',
  'accounting.bill.field.memo': 'Memo',
  'accounting.bill.field.original_name': 'Original Name',
  'accounting.bill.field.bill_no': 'Bill No.',
  'accounting.bill.field.is_ready': 'Ready to Pay',
  'accounting.bill.field.ready': 'Ready',
  'accounting.bill.copy': 'Copy to Bill Code',
  'accounting.bill.is_ready':
    '{ready, select, true {Ready to pay} false {Not Ready to pay} other {Other}}',
  'accounting.bill.field.vendor': 'Vendor',
  'accounting.bill.field.vendor.required': 'Please Select Vendor',
  'accounting.bill.field.color': 'Color',
  'accounting.bill.field.bill_code': 'Bill #',
  'accounting.bill.field.bill_code.required': 'Please input Bill #',
  'accounting.bill.field.inv_date': 'Inv. Date',
  'accounting.bill.field.status': 'Status',
  'accounting.bill.field.paid_amount': 'Paid Amount',
  'accounting.bill.field.auto_mark_ready': 'Auto Mark Ready',
  'accounting.bill.field.pending_amount': 'Pending Amount',
  'accounting.bill.create.tooltip': 'Click the action on the right to create a bill',
  'accounting.bill.field.status.tooltip':
    'Click the action on the right to create or add to an invoice',
  'accounting.bill.field.status.reconciled.tooltip': 'Reconciled with statement',
  'accounting.bill.field.use_hbl_no': 'Use HB/L No.',
  'accounting.bill.field.use_mbl_no': 'Use MB/L No.',
  'accounting.bill.field.auto_mark_ready.tooltip': ' Mark ready when seleted invoices are paid',
  'accounting.bill.create_bill': 'Create Bill',
  'accounting.bill.create_bill_draft': 'Create Draft Bill',
  'accounting.bill.create_invoice': 'Create Invoice',
  'accounting.bill.create_invoice_draft': 'Create Draft Invoice',
  'accounting.bill.create_credit_note': 'Create Credit Note',
  'accounting.bill.create_credit_note_draft': 'Create Draft Credit Note',
  'accounting.bill.create_debit_note': 'Create Debit Note',
  'accounting.bill.create_debit_note_draft': 'Create Draft Debit Note',
  'accounting.bill.outstanding_payables': 'Outstanding Payables',
  'accounting.bill.daily_payables': 'Daily Payables',
  'accounting.bill.paid_payables': 'Paid Payables',
  'accounting.bill.all_payables': 'All Payables',
  'accounting.bill.make_payment': 'Make Payment',
  'accounting.bill.empty': 'No Bills',
  'accounting.bill.applied.success': 'Successfully applied credit',
  'accounting.bill.added.success': 'Successfully added payment',
  'accounting.bill.partially_paid': 'Partially Paid',
  'accounting.bill.include_void': 'Include Void',
  'accounting.bill.create_general_invoice': 'Create General Invoice',
  'accounting.bill.create_general_bill': 'Create General Bill',
  'accounting.bill.general_bill': 'General Bill',
  'accounting.bill.shipment_bill': 'Shipment Bill',
  'accounting.bill.dependents': 'Dependents',
  'accounting.bill.dependents.tooltip': 'First you need link shipment',
  'accounting.bill.create': 'New Bill',
  'accounting.bill.open_receivables': 'Open Receivables',
  'accounting.bill.received_receivables': 'Received Receivables',
  'accounting.bill.all_receivables': 'All Receivables',
  'accounting.bill.total_remaining_amount': 'Total remaining amount',
  'accounting.bill.receive_payment': 'Receive Payment',
  'accounting.bill.new_bill.title': 'New Bill',
  'accounting.bill.create.success': 'Bill created successfully',
  'accounting.bill.delete.success': 'Bill deleted successfully',
  'accounting.bill.publish.success': 'Bill published successfully',
  'accounting.bill.delete.confirm': 'Are you sure to delete this bill?',
  'accounting.bill.delete.already_link.confirm':
    'This Bill already link shipment ({ code }) , Are you sure to delete this bill?',
  'accounting.bill.update.success': 'Bill updated successfully',
  'accounting.bill.link.success': 'Bill linked successfully',
  'accounting.bill.void.success': 'Bill voided successfully',
  'accounting.bill.unlink.success': 'Bill unlinked successfully',
  'accounting.bill.title.shipment_information': 'Shipment Information',
  'accounting.bill.title.billing_information': 'Billing Information',
  'accounting.bill.not_ready.tooltip': 'This bill is not ready to pay',
  'accounting.bill.download': 'Billing Download',
  'accounting.statement.cash_flow_statement': 'Cash Flow Statement',
  'accounting.statement.inflow': 'Inflow',
  'accounting.statement.total_inflow': 'Total Inflow',
  'accounting.statement.expenditure': 'Expenditure',
  'accounting.statement.total_expenditure': 'Total Expenditure',
  'accounting.statement.net_cash_flow': 'Net Cash Flow',
  'accounting.statement.create_title': 'Create New Statement',
  'accounting.statement.net_income_loss': 'Net Income/Loss',
  'accounting.statement.tax': 'Tax',
  'accounting.statement.pre_tax_income': 'Pre-tax Income',
  'accounting.statement.other_expense_total': 'Other Expense Total',
  'accounting.statement.other_expense': 'Other Expense',
  'accounting.statement.other_income_total': 'Total Other Income',
  'accounting.statement.other_income': 'Other Income',
  'accounting.statement.gross_profit': 'Gross Profit',
  'accounting.statement.expense': 'Expense',
  'accounting.statement.total_income': 'Total Income',
  'accounting.statement.total_expense': 'Total Expense',
  'accounting.statement.income_statement': 'Income Statement',
  'accounting.statement.reconcile': 'Confirm Amount',
  'accounting.statement.reconcile.confirm.title': 'Are you sure to confirm this statement amount?',
  'accounting.statement.reconcile.warn.title':
    '{codes} are already included in another confirmed statement.',
  'accounting.statement.submit.select_items_first.title': 'Please select items first',
  'accounting.statement.submit.selected_reconciled_items.title':
    "You've selected items that are already submitted in another statement. Please unselect them and try again.",
  'accounting.statement.reconcile.success': 'Statement amount confirmed successfully',
  'accounting.statement.submit': 'Submit',
  'accounting.statement.convert_to_payment_plan': 'Convert to Payment Plan',
  'accounting.statement.submit.confirm.title': 'Are you sure to submit selected entries?',
  'accounting.statement.convert_to_payment_plan.confirm.title':
    'Are you sure to convert statement to payment plan?',
  'accounting.payment_plan.submit.confirm.title': 'Are you sure to submit payment plan?',
  'accounting.statement.submit.warn.title': '{codes} are already submitted in another statement.',
  'accounting.statement.submit.success': 'Statement submitted successfully',
  'accounting.statement.field.reconciled_by': 'Reconciled By',
  'accounting.statement.field.po_no_list': 'PO #',
  'accounting.statement.field.mbl_no_list': 'MB/L #',
  'accounting.statement.field.hbl_no_list': 'HB/L #',
  'accounting.statement.field.mbl_hbl_no': 'BL #',
  'accounting.statement.field.updated_at': 'Updated At',
  'accounting.statement.revenue': 'Revenue',
  'accounting.statement.total_revenue': 'Total Revenue',
  'accounting.statement.cost_of_goods_sold': 'Cost of Goods Sold',
  'accounting.statement.total_cost_of_goods_sold': 'Total Cost of Goods Sold',
  'accounting.statement.operating_profit': 'Operating Profit',
  'accounting.statement.total_assets': 'Total Assets',
  'accounting.statement.liability_and_equity': "Liabilities & Stockholders' Equity",
  'accounting.statement.total_liability_and_equity': "Total Liabilities & Stockholders' Equity",
  'accounting.pnl.field.revenues': 'Revenues',
  'accounting.pnl.field.expenses': 'Expenses',
  'accounting.pnl.field.received': 'Received',
  'accounting.pnl.field.pnl': 'Profit/Loss',
  'accounting.pnl.field.commodity': 'Commodity',
  'accounting.pnl.field.vessel_voyage': 'Vessel/Voyage',
  'accounting.pnl.field.incl_archived': 'Incl. Archived',
  'accounting.pnl.empty': 'No Items',
  'accounting.pnl.ocean_import_profit': 'Ocean Import Profit & Lost',
  'accounting.pnl.ocean_export_profit': 'Ocean Export Profit & Lost',
  'accounting.pnl.air_import_profit': 'Air Import Profit & Lost',
  'accounting.pnl.air_export_profit': 'Air Export Profit & Lost',
  'accounting.pnl.misc': 'Misc Profit & Lost',
  'accounting.pnl.archive.confirm.title': 'Are you sure you want to archive these shipments',
  'accounting.pnl.archive.success': 'Successfully archived these shipments',
  'accounting.pnl.archive.confirm.content':
    'You will not be able to make changes to them after archiving.',

  'accounting.dc_note.notifications.lock.title': 'Debit/Credit Note Locked',
  'accounting.dc_note.notifications.lock.description': 'This debit/credit note is locked by {name}',
  'accounting.transaction.notifications.lock.title': 'Transaction Locked',
  'accounting.transaction.notifications.lock.description': 'This transaction is locked by {name}',
  'accounting.currency_rate.field.from': 'From',
  'accounting.currency_rate.field.to': 'To',
  'accounting.currency_rate.field.rate': 'Rate',
  'accounting.currency_rate.field.ext_rate': 'External Rate',
  'accounting.currency_rate.field.as_of_date': 'Effective Till',
  'accounting.currency_rate.field.starting_from': 'Starting From',
  'accounting.currency_rate.rate.label': 'From {from} To {to} Currency Rate',
  'accounting.currency_rate.ext_rate.label': 'From {from} To {to} External Currency Rate',
  'accounting.currency_rate.current_rate': 'Current Rate',
  'accounting.currency': 'Currency',
  'accounting.field.subtotals': 'Subtotals',
  'accounting.field.total': 'Total',
  'accounting.actions.add_shipment_documents': 'Add Shipment Documents',
  'accounting.charge_item_code.field.income_gl_code': 'Income G/L Code',
  'accounting.charge_item_code.field.expense_gl_code': 'Expense G/L Code',
  'accounting.charge_item_code.field.credit_note_gl_code': 'Credit Note G/L Code',
  'accounting.charge_item_code.field.debit_note_gl_code': 'Debit Note G/L Code',
  'accounting.charge_item_code.field.tax_code': 'Tax Code',
  'accounting.charge_item_code.field.wht': 'WHT',
  'accounting.view_by_chargeitems': 'View by Charge Items',
  'accounting.view_by_invoices': 'View by Invoices',
  'accounting.include_revenue_cost': 'Include Revenue & Cost',

  'accounting.account_balance.previous': 'Previous Balance',
  'accounting.account_balance.current': 'Current Period Balance',
  'accounting.account_balance.ending': 'Ending Balance',
  'accounting.account_balance.account': 'Account',
  'accounting.account_balance.assets': 'Assets',
  'accounting.account_balance.assets.total': 'Assets Total',
  'accounting.account_balance.liabilities': 'Liabilities',
  'accounting.account_balance.liabilities.total': 'Liabilities Total',
  'accounting.account_balance.equity': 'Equity',
  'accounting.account_balance.equity.total': 'Equity Total',
  'accounting.account_balance.income_expense': 'Income & Expense',
  'accounting.account_balance.income_expense.total': 'Income - Expense',
  'accounting.account_balance.running_balance': 'Running Balance',
  'accounting.tax_code.field.rate': 'Rate',
  'accounting.tax_code.field.received_gl_code': 'G/L Code (Received)',
  'accounting.tax_code.field.paid_gl_code': 'G/L Code (Paid)',
  'accounting.tax_code.field.payable_gl_code': 'G/L Code (Payable)',
  'accounting.tax_code.type.vat': 'VAT',
  'accounting.tax_code.type.sales_tax': 'Sales Tax',
  'accounting.tax_code.type.wht': 'WHT',
  'accounting.reports.field.incl_details': 'Include Details',
  'accounting.all_ar_paid': 'All A/R Received',
  'accounting.field.gl_code': 'G/L Code',
  'accounting.field.gl_code.name': 'G/L Name.',
  'accounting.field.gl_code.description': 'G/L Desc.',
  'accounting.field.gl_code.type': 'G/L Type.',
  'accounting.field.gl_code.is_active': 'Acitve',
  'accounting.field.gl_code.parent_gl_code': 'Parent G/L Code',
  'accounting.gl_code.error.code_already_exists': 'G/L Code already exists',
  'accounting.button.add_from_quotation': 'Add from Quotation',
  'accounting.button.add_from_qtoshipment': 'Add from Shipment Request',
  'accounting.button.add_from_ar': 'Add from A/R',
  'accounting.button.add_from_ap': 'Add from A/P',
  'accounting.year_end_closing.last_year_processed': 'Last Year Processed',
  'accounting.year_end_closing.perform_year_end_closing': 'Perform Year End Closing for {year}',
  'accounting.year_end_closing.cancel_year_end_closing': 'Cancel Year End Closing for {year}',
  'accounting.year_end_closing.perform_year_end_closing_confirmation':
    'Are you sure to perform year end closing for {year}?',
  'accounting.year_end_closing.cancel_year_end_closing_confirmation':
    'Are you sure to cancel year end closing for {year}?',

  'accounting.distribute_by': 'Distribute By',
  'accounting.cbm_total': 'Total CBM',
  'accounting.current_cbm': 'Current CBM',
  'accounting.ratio': 'Ratio',
  'accounting.calculate': 'Calculate',
  'accounting.total_weight': 'Total Weight',
  'accounting.current_weight': 'Current Weight',
  'accounting.account.field.display_info': 'Display Info',
  'accounting.statement.generate_pdf': 'Generate PDF',
  'accounting.statement.generate_debit_note_pdf': 'Generate Debit Note PDF',
  'accounting.date_type.date': 'DATE',
  'accounting.date_type.due_date': 'DUE',
  'accounting.date_type.created_at': 'EST',
  'accounting.arap.lock.confirm': 'Are you sure to lock these items?',
  'accounting.arap.unlock.confirm': 'Are you sure to unlock these items?',
  'accounting.account_receivable_aging_report': 'Account Receivable Aging Report',
  'accounting.account_receivable_aging_report.field.include_till': 'Include Till',
  'accounting.account_receivable_aging_report.excel.aging': 'Aging Report',
};
